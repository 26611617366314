import React, { FC, useMemo } from 'react';
import { Button, EasyFilterView, Input, Toggle } from '@ui';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { MetricTriggerCreateType, MetricTriggerObject } from '@app/interfaces/metric-trigger.type';
import useTranslation from '@app/hooks/use-translation';
import { Filter, KeysForFilteringType, Range } from '@app/components/ui/easy-filter/types';
import {
  useCreateMetricTriggerMutation,
  useEditMetricTriggerMutation,
} from '@app/store/api/metric-trigger.api';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

type AddTriggerFormPropsType = {
  onClose(): void;
  project_id: string;
  metricList: MetricListArrType[] | undefined;
  metricTriggerObject: MetricTriggerObject | undefined;
  modalEditState: boolean;
};

const AddTriggerForm: FC<AddTriggerFormPropsType> = (props) => {
  const { onClose, project_id, metricList, modalEditState, metricTriggerObject } = props;
  const { t } = useTranslation('pages.triggers');
  const [createTrigger] = useCreateMetricTriggerMutation();
  const [editTrigger] = useEditMetricTriggerMutation();
  const formInputBlockStyle = cn('flex', 'items-center', 'w-full', 'justify-between');
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<MetricTriggerCreateType>({
    defaultValues: modalEditState
      ? {
          preset: metricTriggerObject?.preset,
          description: metricTriggerObject?.description,
          enabled: metricTriggerObject?.enabled,
          project_id: metricTriggerObject?.project.project_id,
          subject: metricTriggerObject?.subject,
        }
      : { project_id: project_id, enabled: false, preset: { filter: [] } },
  });
  const aliasesKeys: KeysForFilteringType[] = useMemo(() => {
    return (
      metricList?.map((metric) => ({
        id: metric.metric_id,
        title: metric.name,
        type_value: metric.result_value_type,
      })) || []
    );
  }, [metricList]);
  function onSubmitForm(data: MetricTriggerCreateType) {
    modalEditState
      ? metricTriggerObject?.trigger_task_id &&
        editTrigger({ body: data, params: { trigger_id: metricTriggerObject?.trigger_task_id } })
      : createTrigger(data);
    onClose();
  }
  const filterData: Filter & Range = useMemo(
    () => ({
      range: {
        type: 'n',
        parameters: [],
      },
      filter: watch('preset.filter') || [],
    }),
    [watch],
  );
  return (
    <form
      onSubmit={handleSubmit(onSubmitForm)}
      className="w-full flex flex-col gap-[20px] py-[20px]"
    >
      <div className={formInputBlockStyle}>
        <div>{t('form.subject')}</div>
        <div className="w-[300px]">
          <Input
            {...register('subject', { required: { value: true, message: ' ' } })}
            maxLength={200}
            error={errors.subject?.message}
          />
        </div>
      </div>
      <div className={formInputBlockStyle}>
        <div>{t('form.description')}</div>
        <div className="w-[300px]">
          <Input
            {...register('description', { required: { value: modalEditState, message: ' ' } })}
            maxLength={1000}
            error={errors.description?.message}
          />
        </div>
      </div>
      <div className={formInputBlockStyle}>
        <div>{t('form.enabled')}</div>
        <div className="w-[300px]">
          <Toggle checked={watch('enabled')} onChecked={(status) => setValue('enabled', status)} />
        </div>
      </div>
      <div className={formInputBlockStyle}>
        <div>{t('form.preset')}</div>
        <div className="w-[300px]">
          <EasyFilterView
            modalMode
            customFilterLabel={t('form.filter_label')}
            keysForFiltering={aliasesKeys}
            data={filterData}
            onChangeHandler={(preset) => setValue('preset', { filter: preset.filter })}
          />
        </div>
      </div>
      <div>
        <Button type="submit" label={t('modal.submit_button_label')} />
        <Button onClick={onClose} label={t('modal.cancel_button_label')} fill="linked" />
      </div>
    </form>
  );
};

export default AddTriggerForm;
