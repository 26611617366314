import { OptionItem } from '@app/components/ui/select/select.type';
import useTranslation from '@app/hooks/use-translation';
import { ReportTaskFormType, ReportTaskObjectType } from '@app/interfaces/report-tasks';
import {
  useCreateReportTasksMutation,
  useEditReportTasksMutation,
} from '@app/store/api/report_recipients.api';
import { i18nCustom } from '@app/utils/i18n-custom';
import { EasyModal, Input, Select, Toggle, Button } from '@ui';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
type ReportTaskModalFormPropsType = {
  show: boolean;
  onClose(): void;
  activeReportTask: string | undefined;
  live_report_id: string | undefined;
  modalEditMode: boolean;
  reportTaskObject: ReportTaskObjectType | undefined;
  changeTaskModalState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportTaskModalForm: FC<ReportTaskModalFormPropsType> = (props) => {
  const {
    show,
    onClose,
    modalEditMode,
    changeTaskModalState,
    activeReportTask,
    reportTaskObject,
    live_report_id,
  } = props;
  const { t: reportTask } = useTranslation('pages.reports.report_task');
  const periodOptions: OptionItem[] = [
    { value: 'last-day', title: i18nCustom('pages.reports.report_task.prev_day') },
    { value: 'last-week', title: i18nCustom('pages.reports.report_task.prev_week') },
    { value: 'last-month', title: i18nCustom('pages.reports.report_task.prev_month') },
  ];
  const { register, setValue, watch, handleSubmit } = useForm<ReportTaskFormType>({
    defaultValues: !modalEditMode
      ? undefined
      : {
          description: reportTaskObject?.description,
          enabled: reportTaskObject?.enabled,
          settings: { type: reportTaskObject?.settings.type },
          subject: reportTaskObject?.subject,
        },
  });

  const [createTask] = useCreateReportTasksMutation();
  const [editTask] = useEditReportTasksMutation();
  function onSubmit(data: ReportTaskFormType) {
    modalEditMode
      ? activeReportTask && editTask({ body: data, params: { report_task_id: activeReportTask } })
      : live_report_id && createTask({ body: data, params: { live_report_id } });
    changeTaskModalState(false);
  }
  return (
    <EasyModal
      label={reportTask('fields.settings')}
      variant="mediumW650"
      withoutFooter
      show={show}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex pb-4 flex-col gap-[20px]">
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{reportTask('fields.subject')}</span>
          <div className="w-[280px]">
            <Input {...register('subject')} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{reportTask('fields.description')}</span>
          <div className="w-[280px]">
            <Input {...register('description')} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{reportTask('fields.enabled')}</span>
          <div className="ml-[4px] w-[280px]">
            <Toggle checked={watch('enabled')} onChecked={(value) => setValue('enabled', value)} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{reportTask('fields.period')}</span>
          <div className="w-[280px]">
            <Select
              dropTop
              defaultValue={watch('settings.type')}
              onChange={(value) => setValue('settings', value as any)}
              options={periodOptions}
            />
          </div>
        </div>
        <div className=" justify-center w-full flex pt-[10px]">
          <Button type="submit" label={reportTask('submit_form_button')} />
        </div>
      </form>
    </EasyModal>
  );
};
export default ReportTaskModalForm;
