import React, { FC } from 'react';
import { EasyModal } from '@ui';
type InformMessagesDescriptionModalPropsType = {
  message: string;
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const InformMessagesDescriptionModal: FC<InformMessagesDescriptionModalPropsType> = (props) => {
  const { show, onClose, message } = props;
  return (
    <EasyModal withoutFooter show={show} onClose={onClose.bind(null, false)}>
      <div>{message}</div>
    </EasyModal>
  );
};

export default InformMessagesDescriptionModal;
