import React, { FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { CircleButton, Modal, PageHeader, PermissionAccessWrapper, Skeleton } from '@ui';
import { TrashIcon } from '@icons';
import RecordMeta from '@app/components/audio-build/record-meta';
import AudioChat from '@app/components/audio-build/audio-chat';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { useAudioContext } from '@app/components/audio-build/record-provider';
import WaveAudio from '@app/components/audio-build/waveaudio/wave-audio.component';
import { CalculatedRecordMetric, Dialog, DialogWords } from '@app/interfaces/records';

import { userPermissions } from '@app/utils/user-permissions';

import useProjectTemplate from '@app/hooks/use-project-template';

import { useGetChatDetailsQuery } from '@app/store/api/chat-api/all-chats.api';

import { MetaRecords } from '@app/store/reducers/meta.slice';

import { v4 } from 'uuid';

import { VoiceEmotion } from '@app/interfaces/record-transcription';

import RecordMetric from './record-metric';

const AudioContainer: FC<PropsFromRedux> = (props) => {
  const {
    removeRecordRequest,
    recordsState,
    getFileRecordThunk,
    compositeRecordRequest,
    recordMetaRequest,
    metaState,
  } = props;
  const { recordId, projectId } = useAudioContext();
  const { currentTemplate } = useProjectTemplate();
  const { compositeRecord, audio, isPending, recordIsPending } = recordsState;
  const { isPending: isPendingMeta, recordMeta } = metaState;
  const [currentMetricByRegionId, setCurrentMetricByRegionId] = useState<number>(0);
  const [activeMetric, changeActiveMetric] = useState<CalculatedRecordMetric[string]>();
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [currentAsrModel, changeAsrModel] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const pageFrom = location.pathname.split('/')[1];
  const { t } = useTranslation('components.audio');
  const [activeChatTiming, setActiveChatTiming] = useState<
    { from: number; to: number } | undefined
  >();
  const { data: chatRecord } = useGetChatDetailsQuery(
    {
      params: { chat_id: recordId, project_id: projectId },
    },
    { skip: currentTemplate === 'voice' },
  );

  const [activeMessageId, setActiveMessageId] = useState<Array<string> | null>(null);
  useEffect(() => {
    if (currentTemplate === 'chat') return;
    recordMetaRequest({ record_id: recordId });
  }, [currentTemplate, recordId, recordMetaRequest]);

  useEffect(() => {
    if (currentTemplate === 'chat') return;
    compositeRecordRequest({ currentAsrModel, projectId, recordId });
  }, [compositeRecordRequest, currentAsrModel, currentTemplate, projectId, recordId]);

  useEffect(() => {
    if (currentTemplate === 'chat') return;
    getFileRecordThunk({ recordId });
  }, [currentTemplate, getFileRecordThunk, projectId, recordId]);
  function removeRecord() {
    if (recordId) {
      removeRecordRequest({ items: [recordId] });
      navigate(window.location.pathname.split('/').splice(0, 3).join('/'));
    }
  }
  function handleUpdateRecord(asrModel: string) {
    changeAsrModel(asrModel);
  }

  async function handleCopyText() {
    // eslint-disable-next-line compat/compat
    await window.navigator.clipboard.writeText(window.location.href);
    toast.success(t('link_copied'));
  }
  function handleChangeActiveRecordMetric(metricId: string | undefined, type: 'once' | 'all') {
    switch (type) {
      case 'once': {
        if (metricId && compositeRecord) {
          const currentIds = compositeRecord?.calculatedRecordMetric[metricId]?.settings?.ids || [];
          if (compositeRecord.calculatedRecordMetric[metricId] !== activeMetric) {
            changeActiveMetric(compositeRecord.calculatedRecordMetric[metricId]);
            setCurrentMetricByRegionId(0);
            currentIds &&
              setActiveMessageId([
                String(compositeRecord?.calculatedRecordMetric[metricId]?.settings?.messageId?.[0]),
              ]);
          } else {
            let currentRegion = 0;
            if (currentIds.length - 1 > currentMetricByRegionId) {
              setCurrentMetricByRegionId((prevState) => prevState + 1);
              currentRegion = currentMetricByRegionId + 1;
            } else {
              setCurrentMetricByRegionId(0);
            }
            setActiveMessageId([
              compositeRecord?.calculatedRecordMetric[metricId]?.settings?.messageId?.[
                currentRegion
              ] || '',
            ]);
          }
        }
        break;
      }
      case 'all': {
        if (metricId !== undefined && compositeRecord?.calculatedRecordMetric[metricId]) {
          changeActiveMetric(compositeRecord.calculatedRecordMetric[metricId]);
        } else {
          changeActiveMetric(undefined);
        }
        break;
      }
    }
  }

  const chatMeta = useMemo(() => {
    const result: Partial<MetaRecords> = {};
    for (const key in chatRecord?.metadata) {
      result[key] = chatRecord?.metadata[key].reduce((acc, current) => {
        acc[current.alias || current.meta_name] = current.meta_value;
        return acc;
      }, {});
    }
    return result;
  }, [chatRecord?.metadata]) as MetaRecords;
  const chatDialog: Dialog[] = useMemo(() => {
    return (
      chatRecord?.markup.map((dialogItem, index) => {
        const roleIndex = chatRecord.names.findIndex((name) => name.userid === dialogItem.userid);
        const currentMemberRole = chatRecord.names[roleIndex];
        const currentRoleIcon = chatRecord?.chat_roles.find(
          (chatItem) => chatItem.crole_id === currentMemberRole?.croles[0],
        )?.icon;
        return {
          id: `chat_message_${v4()}`,
          channel_id: String(roleIndex),
          segment_id: String(index),
          channel_config: {
            settings_channels_id: currentMemberRole?.croles[0],
            name: currentMemberRole?.name,
            channel: roleIndex,
            icon: currentRoleIcon || '',
          },
          to: 0,
          from: 0,
          emotion: { score: 1, tag: 'neutral' } as VoiceEmotion,
          words:
            (dialogItem.message.split(' ').map((word, index) => ({
              word: word,
              from: 0,
              to: 0,
              segment_id: String(index),
              word_idx: v4(),
              channel_id: String(roleIndex),
              emotion: { score: 1, tag: 'neutral' } as VoiceEmotion,
            })) as DialogWords[]) || [],
        };
      }) || []
    );
  }, [chatRecord]);

  // console.log(moment('2024-07-13 00:00:00').fromNow(), 'lalalal');

  const metaOfRecord = {
    chat: chatMeta,
    voice: recordMeta,
  };
  const dialogs = {
    chat: chatDialog,
    voice: compositeRecord?.dialogs,
  };
  const name = {
    chat: `${chatRecord?.METAChatID} ${chatRecord?.METAdatetime}`,
    voice: recordMeta?.record_name,
  };
  const renderMetricAndMetaBlocks = (
    <div
      className={cn(
        `${currentTemplate === 'chat' ? '' : 'mt-[12px]'}`,
        isFullScreen && 'order-2 basis-1/2 pr-[10px] items-center flex-row w-full',
      )}
    >
      <div className="">
        {isPendingMeta ? (
          <Skeleton width="100%" height={62} />
        ) : (
          <RecordMeta meta={metaOfRecord[currentTemplate || 'voice']} />
        )}
      </div>
      {projectId &&
        compositeRecord?.calculatedRecordMetric &&
        Object.keys(compositeRecord?.calculatedRecordMetric).length > 0 && (
          <div className="my-[12px]">
            <RecordMetric
              metricCases={compositeRecord.known_metrics_cases}
              onChangeActiveMetric={handleChangeActiveRecordMetric}
              projectMetric={compositeRecord.calculatedRecordMetric}
            />
          </div>
        )}
    </div>
  );
  return (
    <div className="">
      <PageHeader
        label={
          <div className="text-speech_analitics">
            <div className="mb-0">{t('audio')}</div>
            <div className="text-speech_analitics text-[13px]">
              {isPendingMeta ? (
                <Skeleton height="14px" width="100px" />
              ) : (
                <span
                  className="mb-[2px] pl-[0px] cursor-pointer hover:opacity-80"
                  onClick={handleCopyText}
                >
                  {(name && name[currentTemplate || 'voice']) || '-'}
                </span>
              )}
              <CircleButton
                hintTitle={t('popup_hints.copy_link')}
                hintPlace="top"
                icon="CopyIcon"
                size={15}
                className="text-defaultColor ml-[14px]"
                onClick={handleCopyText}
              />
            </div>
          </div>
        }
      >
        {pageFrom === 'records' && (
          <PermissionAccessWrapper
            permissions={'action'}
            tag={userPermissions.action.actionDeleteRecord}
          >
            <button
              className="text-basic_red p-[5px] inline-flex items-center"
              onClick={setIsDeleteOpen.bind(null, true)}
            >
              <TrashIcon size={15} className="text-basic_red mr-[5px]" />
              {t('delete')}
            </button>
          </PermissionAccessWrapper>
        )}
      </PageHeader>
      <div className={cn('flex  w-full', isFullScreen ? ' flex-wrap' : ' gap-[12px]')}>
        <div
          className={cn(
            isFullScreen ? 'basis-[100%]  flex-1 w-[100%]' : 'basis-[48%] order-1 w-full',
          )}
        >
          {currentTemplate === 'voice' ? (
            !recordIsPending && audio && compositeRecord?.dialog_words ? (
              <div className={cn(isFullScreen ? 'basis-2/2' : 'basis-1/2')}>
                <WaveAudio
                  recordName={recordMeta?.record_name}
                  wordsTimeList={compositeRecord?.wordsTimeList}
                  audio={audio}
                  emotionTrack={compositeRecord.emotionTrack}
                  activeAndChangeCurrentRegion={[
                    currentMetricByRegionId,
                    setCurrentMetricByRegionId,
                  ]}
                  channelSettings={compositeRecord?.dialogChannels}
                  activeMetricWords={activeMetric}
                  onChangeActiveMessageId={setActiveMessageId}
                  activeMessageId={activeMessageId}
                  activeChartTiming={activeChatTiming}
                  changeFullScreen={setFullScreen.bind(null, (prev) => !prev)}
                  dialogs={compositeRecord?.dialogs}
                />
              </div>
            ) : (
              <Skeleton height={286} width="100%" label={t('skeleton_loading_label')} />
            )
          ) : null}
          {!isFullScreen && renderMetricAndMetaBlocks}
        </div>
        <div
          className={cn(
            'basis-1/2 sticky top-[12px] h-full',
            isFullScreen ? 'order-3 mt-[12px]' : ' order-2',
          )}
        >
          {isPending && !compositeRecord ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <AudioChat
              activeMetric={activeMetric}
              activeMessageId={activeMessageId}
              onChangeActiveChartTiming={setActiveChatTiming}
              transcription={dialogs[currentTemplate || 'voice']}
              currentAsrModel={compositeRecord?.currentAsrModel}
              handleUpdateRecordByAsrModel={handleUpdateRecord}
              audioBoolean={compositeRecord?.recordIsRecognized}
              currentTemplate={currentTemplate || 'voice'}
            />
          )}
        </div>
        {isFullScreen && renderMetricAndMetaBlocks}
      </div>
      <Modal
        id="remove_record"
        value={isDeleteOpen}
        size="xs"
        title={t('delete_record')}
        setValue={setIsDeleteOpen}
        onApply={removeRecord}
        variant="removal"
      >
        {t('record_will_deleted')}
      </Modal>
    </div>
  );
};

export default connector(AudioContainer);
