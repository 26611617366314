import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromTrigger } from '@app/pages/email-confirmation';

export const unsubscribeFromTriggerRoute = (
  <Route path={navigationRoutes.unsubscribeFromTrigger}>
    <Route index element={<UnsubscribeFromTrigger />} />
  </Route>
);
