import React, { FC, useState } from 'react';

import { useAuth } from '@app/utils/reactTokenAuth';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

// import { RtkLogoIcon } from '@app/components/ui/icons/default';

import { Button, EasyModal, PermissionAccessWrapper } from '@app/components/ui';

import { TemplatesType } from '@app/hooks/use-project-template';

import { userPermissions } from '@app/utils/user-permissions';

import HandleMetricSpecialToggle from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals/handle-metric-modal/blocks/handle-metric-special-toggle';

import useTranslation from '@app/hooks/use-translation';

import Icon from '@app/components/ui/icons';

import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { useAppSelector } from '@app/store/store';

import logo from '../../../imgs/imagesToBuild';

import AuthMenuBlock from './auth-menu.block';
type HeaderPropsType = {
  changeTemplateHandler: (template: TemplatesType) => void;
  currentTemplate: TemplatesType;
};

const HeaderBlock: FC<HeaderPropsType> = (props) => {
  const { changeTemplateHandler, currentTemplate } = props;
  const { t } = useTranslation('components.header');
  const templatesListVariables = [
    { title: t('chats'), value: 'chat' },
    { title: t('calls'), value: 'voice' },
  ];
  const [currentTemplateLocal, changeTemplateLocal] = useState(currentTemplate);

  const [modal, changeModalState] = useState(false);
  const [logged] = useAuth();
  const renderAuthMenu = logged && <AuthMenuBlock />;
  const renderCurrentIndexLink = logged ? navigationRoutes.main : navigationRoutes.authorization;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathTree = pathname.split('/').filter((path) => path);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  // eslint-disable-next-line
  const replacePathSet: Record<string, string> = {
    [navigationRoutes.analytics]: navigationRoutes.chatAnalytic,
    [navigationRoutes.records]: navigationRoutes.allChats,
    [navigationRoutes.metric]: navigationRoutes.chatMetricTemplates,
    [navigationRoutes.projectRecords]: navigationRoutes.chatProjectRecords,
    [navigationRoutes.reports]: navigationRoutes.chatReports,
  };
  function pathByTemplateReplacer() {
    const currentPath = pathTree[0];
    const pathSetToArr = Object.entries(replacePathSet);
    for (let i = 0; i < pathSetToArr.length; i++) {
      const [voicePath, chatPath] = pathSetToArr[i];
      if (voicePath === currentPath) {
        return chatPath;
      } else if (chatPath === currentPath) {
        return voicePath;
      }
    }
    return currentPath;
  }

  function handleChangeTemplateLocal(value: string) {
    changeTemplateLocal(value as 'chat' | 'voice');
    changeModalState(true);
  }
  function handleChangeTemplateGlobal() {
    changeTemplateHandler(currentTemplateLocal);
    changeModalState(false);

    updateUserSettings({ ...userSettings, lastProjectRecords: null }).finally(() =>
      navigate(`/${pathByTemplateReplacer() || ''}`),
    );
  }
  function handleDiscardChangeTemplateGlobal() {
    changeTemplateLocal(currentTemplate);
    changeModalState(false);
  }
  const switchProjectTemplateMarkup = (
    <div className="flex items-center w-[300px]">
      <HandleMetricSpecialToggle
        isActive={currentTemplateLocal}
        onChange={handleChangeTemplateLocal}
        valuesList={templatesListVariables}
      />
    </div>
  );

  return (
    <div className="bg-white flex justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={renderCurrentIndexLink} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px]">
          <img src={logo} alt="LOGO" />
        </div>
      </NavLink>
      {process.env.REACT_APP_THEME !== 'rtk' && (
        <PermissionAccessWrapper permissions="display" tag={userPermissions.display.displayChats}>
          {switchProjectTemplateMarkup}
        </PermissionAccessWrapper>
      )}
      <div>{renderAuthMenu}</div>
      <EasyModal
        withoutFooter
        withoutContentPadding
        withoutLabel
        variant="small"
        show={modal}
        onClose={() => changeModalState(false)}
      >
        <div className="w-full h-full py-10">
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <div className="">
              <Icon
                size={30}
                className="text-defaultColor p-4 bg-defaultColor bg-opacity-10 rounded-full"
                name={'HelpCircleIcon'}
              />
            </div>
            <div className="block">
              <div className="text-[14px] leading-normal text-center w-full text-1color font-medium">
                {t('modal_warning_text')
                  .split(/[!.]/g)
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      {i}
                      {index === 0 ? '!' : '.'} <br />
                    </React.Fragment>
                  ))}
              </div>
            </div>
            <div className="flex items-center flex-col justify-center gap-[10px]">
              <Button onClick={handleChangeTemplateGlobal} label={t('go')} />
              <Button
                fill="linked"
                onClick={handleDiscardChangeTemplateGlobal}
                label={t('cancel')}
              />
            </div>
          </div>
        </div>
      </EasyModal>
    </div>
  );
};

export default HeaderBlock;
