import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromTriggerError } from '@app/pages/email-confirmation';

export const unsubscribeFromTriggerErrorRoute = (
  <Route path={navigationRoutes.unsubscribeFromTriggerError}>
    <Route index element={<UnsubscribeFromTriggerError />} />
  </Route>
);
