import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { Subscribe } from '@app/pages/email-confirmation';

export const subscribeRoute = (
  <Route path={navigationRoutes.subscribe}>
    <Route index element={<Subscribe />} />
  </Route>
);
