export const metricIconsData = {
  meta: 'meta',
  summ_speech_duration: 'summspeechduration',
  summ_silent_duration: 'summsilentduration',
  interruptions: 'interruptions',
  max_pause_duration: 'maxpauseduration',
  avg_pause_duration: 'avgpauseduration',
  find_words: 'findwords',
  max_monolog_duration: 'maxmonologduration',
  speech_rate_duration: 'speechrateduration',
  calculated: 'calculated',
  pause_exceed: 'pauseexceed',
  max_conflict: 'maxconflict',
  avg_conflict: 'avgconflict',
  classifier: 'classifier',
  tags: 'tagsmetricicon',
  //chat
  c_pause_exceed: 'pauseexceed',
  c_longest_pause_duration: 'maxpauseduration',
  c_avg_pause_duration: 'avgpauseduration',
  c_find_words: 'findwords',
  c_num_words_message: 'maxmonologduration',
};
