export const userPermissions = {
  display: {
    displayProjectMetricsResult: 'displayProjectMetricsResult',
    displayExternalASRModels: 'displayExternalASRModels',
    displayMetricTemplates: 'displayMetricTemplates',
    displayRecognizeRules: 'displayRecognizeRules',
    displayAPModel: 'displayAPModel',
    displayAuditLogs: 'displayAuditLogs',
    displayDashboards: 'displayDashboards',
    displayLiveReports: 'displayLiveReports',
    displayProjectMetrics: 'displayProjectMetrics',
    displayProjectPreset: 'displayProjectPreset',
    displayProjects: 'displayProjects',
    displayDictionaries: 'displayDictionaries',
    displayPresets: 'displayPresets',
    displayRecords: 'displayRecords',
    displayChannels: 'displayChannels',
    displayAliases: 'displayAliases',
    displaySettings: 'displaySettings',
    displayRepStatusCol: 'displayRepStatusCol',
    displayUsers: 'displayUsers',
    dis: 'dis',
    displayChatsProjects: 'displayChatsProjects',
    displayChatProjectMetrics: 'displayChatProjectMetrics',
    displayChats: 'displayChats',
    displayDashboardsShare: 'displayDashboardsShare',
    displayLiveReportsTasks: 'displayLiveReportsTasks',
    displaySender: 'displaySender',
    displayIntegrationAuth: 'displayIntegrationAuth',
  },
  action: {
    actionDeleteRecords: 'actionDeleteRecords',
    actionDeleteRecord: 'actionDeleteRecord',
    actionTakeDashboard: 'actionTakeDashboard',
    actionUploadRecord: 'actionUploadRecord',
    actionCloneLiveReport: 'actionCloneLiveReport',
    actionEditLiveReport: 'actionEditLiveReport',
    actionEditLiveReportGraph: 'actionEditLiveReportGraph',
    actionPutLiveReportGraphsLayout: 'actionPutLiveReportGraphsLayout',
    actionGetBilling: 'actionGetBilling',
  },
};
