import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from '@app/hooks/use-translation';
import { useGetInformMessagesFromTgAndEmailQuery } from '@app/store/api/inform-messages.api';
import InformMessagesTableBlock from '@app/pages/settings/blocks/inform-messages/inform-messages-table.block';
import { Select } from '@ui';
import { OptionItem } from '@app/components/ui/select/select.type';
import {
  MessagesRequestSourceEmailType,
  MessagesRequestSourceTelegramType,
  MixedMessagesRequestType,
  MixedMessagesType,
} from '@app/interfaces/inform-messages';
const informMessagesPlatforms: OptionItem[] = [
  { title: 'telegram', value: 'telegram' },
  { title: 'email', value: 'email' },
];
type PlatformsType = 'telegram' | 'email';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;
const InformMessagesPage = () => {
  const [currentPlatform, changePlatform] = useState<PlatformsType>(
    String(informMessagesPlatforms[1].value) as PlatformsType,
  );

  const [messagesData, setMessagesData] = useState<MixedMessagesType | undefined>();
  const [filterParams, changeFilterParams] = useState<MixedMessagesRequestType>({
    telegram: {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
      source: (messagesData?.telegram.source as MessagesRequestSourceTelegramType) || 'report',
    },
    email: {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
      source: messagesData?.email.source || 'report',
    },
  });

  const { data } = useGetInformMessagesFromTgAndEmailQuery(filterParams);
  useEffect(() => {
    setMessagesData(data);
  }, [data]);
  const informMessagesSourceOptions: OptionItem[] = useMemo(
    () =>
      currentPlatform === 'telegram'
        ? [
            { title: 'report', value: 'report' },
            { title: 'trigger', value: 'trigger' },
          ]
        : [
            { title: 'report', value: 'report' },
            { title: 'trigger', value: 'trigger' },
            { title: 'sender', value: 'sender' },
          ],
    [currentPlatform],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { t } = useTranslation('pages.settings');

  return (
    <div className="pt-[30px] w-full">
      <div className="flex  items-center justify-start gap-[20px]">
        <div className="flex items-center gap-[5px]">
          <span>Platform:</span>
          <Select
            defaultValue={currentPlatform}
            onChange={(value) => {
              changePlatform(String(value) as PlatformsType);
              changeFilterParams((prev) => ({
                ...prev,
                [currentPlatform]: {
                  ...prev[currentPlatform],
                  limit: DEFAULT_LIMIT,
                  offset: DEFAULT_OFFSET,
                },
              }));
            }}
            options={informMessagesPlatforms}
          />
        </div>
        <div className="flex items-center gap-[5px]">
          <span>Source:</span>
          <Select
            defaultValue={
              filterParams?.[currentPlatform].source ||
              messagesData?.[currentPlatform].source ||
              'report'
            }
            onChange={(value) =>
              changeFilterParams((prev) => ({
                ...prev,
                [currentPlatform]: {
                  ...prev[currentPlatform],
                  source: value as MessagesRequestSourceEmailType,
                  limit: DEFAULT_LIMIT,
                  offset: DEFAULT_OFFSET,
                },
              }))
            }
            options={informMessagesSourceOptions}
          />
        </div>
      </div>
      <div className="w-full pt-[30px]">
        <InformMessagesTableBlock
          changeRequestParams={changeFilterParams}
          currentPlatform={currentPlatform}
          messagesOffset={filterParams[currentPlatform].offset as number}
          // setMessagesOffset={setMessagesOffset}
          messagesLimit={filterParams[currentPlatform].limit as number}
          // setMessagesLimit={setMessagesLimit}
          tableData={messagesData?.[currentPlatform]}
        />
      </div>
    </div>
  );
};

export default InformMessagesPage;
