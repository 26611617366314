import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

import { CircleButton, EasyModal, Loading } from '@ui';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import connector, { PropsFromRedux } from '@app/utils/store';
import { CompositeMetricData } from '@app/interfaces/metric.type';
import useTranslation from '@app/hooks/use-translation';
import { AllStatusProject } from '@app/interfaces/analytics';
import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';
import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import useProjectTemplate from '@app/hooks/use-project-template';
import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';

import BlocklyBlock from './blockly.block';

const CompositeMetricBlock: FC<
  {
    show: boolean;
    onClose: () => void;
    label: ReactNode;
    metricSettings: MetricObjType;
    editModal: boolean;
    metricList?: MetricListArrType[];
  } & PropsFromRedux
> = (props) => {
  const { metricSettings, show, label, onClose, editModal, metricSettingsReset, metricList } =
    props;
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { t } = useTranslation('pages.metric');
  const { t: tf } = useTranslation('pages.metricTemplates');
  const { currentTemplate } = useProjectTemplate();
  const [createMetricChat] = useCreateProjectChatMetricGroupMutation();
  const [editMetricChat] = useEditProjectChatMetricGroupMutation();
  const [createMetricVoice] = useCreateProjectMetricGroupMutation();
  const [editMetricVoice] = useEditProjectMetricGroupMutation();
  const createMetricMutation = { chat: createMetricChat, voice: createMetricVoice };
  const editMetricMutation = { chat: editMetricChat, voice: editMetricVoice };
  const [calculatedResult, changeCalculatedResult] = useState<CompositeMetricData>({
    formula: { root: undefined },
    metricType: metricSettings?.result_value_type || 'num',
    name: metricSettings?.name || `${project?.name || ''} calculated metric`,
    visualization: metricSettings?.visualization || 'native',
  });

  const DEFAULT_CALCULATED_RESULT: CompositeMetricData = {
    formula: { root: undefined },
    metricType: 'num',
    name: `${project?.name || ''} calculated metric`,
    visualization: 'native',
  };

  const DEFAULT_CALCULATED_METRIC_DATA = {
    name: metricSettings?.name || `${project?.name || ''} calculated metric`,
    description: metricSettings?.name || `${project?.name || ''} calculated metric`,
    type: 'calculated',
    result_value_type: metricSettings?.result_value_type || 'num',
    settings: {
      formula: {
        root: {
          element: calculatedResult?.formula.root,
        },
      },
    },
  };

  useEffect(() => {
    metricSettings &&
      changeCalculatedResult({
        formula: { root: metricSettings?.settings.formula || undefined },
        metricType: metricSettings?.result_value_type || 'num',
        visualization: metricSettings?.visualization || 'native',
        name: metricSettings?.name || `${project?.name || ''} calculated metric`,
      });
  }, [metricSettings, project]);

  function resetCachedInputFromScreen() {
    document?.querySelector('.blocklyHtmlInput') &&
      document?.querySelector('.blocklyHtmlInput')?.remove();
  }

  async function onCloseHandler() {
    await changeCalculatedResult(DEFAULT_CALCULATED_RESULT);
    await metricSettingsReset();
    resetCachedInputFromScreen();
    onClose?.();
  }

  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    const textToCopy = metricSettings?.metric_id;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings?.metric_id]);
  function metricCalculateDefaultSettings() {
    return {
      metric_settings: {
        ...DEFAULT_CALCULATED_METRIC_DATA,
        name:
          calculatedResult.name ||
          metricSettings?.result_value_type ||
          `${project?.name || ''} calculated metric`,
        order: calculatedResult.visualization === 'native' ? 1000 : 100,
        result_value_type:
          calculatedResult.metricType || metricSettings?.result_value_type || 'num',
        visualization: calculatedResult.visualization || 'native',
        settings: { formula: { root: { element: calculatedResult.formula.root } } },
      },
    };
  }
  function errorFormulaResponse(calculatedData, status: 'new' | 'edit') {
    changeCalculatedResult((previous) => ({
      ...previous,
      metric_settings: calculatedData.metric_settings,
    }));
    toast.success(
      t(status === 'new' ? 'composite_fail_create_metric' : 'composite_fail_edit_metric'),
    );
  }

  function onSubmitMetricFormula() {
    const calculatedData = metricCalculateDefaultSettings();
    if (project?.project_id) {
      if (editModal && metricSettings?.metric_id) {
        editMetricMutation[currentTemplate || 'voice']({
          params: { metric_id: metricSettings?.metric_id, project_id: project.project_id },
          body: calculatedData.metric_settings as MetricObjType,
        })
          .unwrap()
          .then(() => {
            toast.success(t('composite_success_edit_metric'));
            onClose();
          })
          .catch(() => {
            errorFormulaResponse(calculatedData, 'edit');
          });
      } else {
        createMetricMutation[currentTemplate || 'voice']({
          params: { project_id: project?.project_id },
          body: calculatedData.metric_settings as MetricObjType,
        })
          .unwrap()
          .then(() => {
            toast.success(t('success_create_metric'));
            resetCachedInputFromScreen();
            onClose();
          })
          .catch(() => {
            errorFormulaResponse(calculatedData, 'new');
          });
        // createMetricRequest({
        //   params: { project_id: project?.project_id },
        //   data: { ...calculatedData },
        // })
        //   .then(() => {
        //     toast.success(t('success_create_metric'));
        //     resetCachedInputFromScreen();
        //     onClose();
        //   })
        //   .catch(() => {
        //     errorFormulaResponse(calculatedData, 'new');
        //   });
      }
    }
  }
  const intermediateStatusesDisable = (project) => {
    if (project?.status === AllStatusProject.FILLING) return true;
    if (project?.status === AllStatusProject.STARTING) return true;
    if (project?.status === AllStatusProject.STOPPING) return true;
    return project?.status === AllStatusProject.ACTIVE;
  };

  if (!metricSettings && editModal && !metricList) return <Loading />;

  return (
    <EasyModal
      show={show}
      onClose={onCloseHandler}
      label={label}
      onSuccess={onSubmitMetricFormula}
      customLayer={90}
      isAutoClosable={false}
      isNotContentClosable
      disabled={intermediateStatusesDisable(project)}
      isFull
    >
      {(metricSettings || !editModal) && (
        <BlocklyBlock
          previousState={calculatedResult}
          onChange={changeCalculatedResult}
          height="100%"
          isEditModal={editModal}
          minWidth="1000"
          metric={metricList || []}
          metricSettings={
            editModal && metricSettings
              ? metricSettings
              : metricCalculateDefaultSettings().metric_settings
          }
        />
      )}
      <div className="flex items-end justify-end absolute w-[1040px] h-[57px] gap-[40px]">
        <span className="text-0color text-[15px]">{tf('metric_id')}</span>
        <div className="flex gap-1 items-center">
          <p>{metricSettings?.metric_id}</p>
          <CircleButton
            onClick={copyToClipboard}
            icon="CopyIcon"
            className="text-action"
            size={16}
          />
        </div>
      </div>
    </EasyModal>
  );
};

export default connector(CompositeMetricBlock);
