import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { NotFoundedPage } from '@app/pages';
import useTranslation from '@app/hooks/use-translation';
import { SkeletonPageLoading } from '@ui';
import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import { useGetChatLiveReportSettingsQuery } from '@app/store/api/chat-api/live-reports.api';
import { useGetChatProjectMetricsListQuery } from '@app/store/api/chat-api/chat-metric.api';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';
import { LiveReport } from '@app/interfaces/report.type';

import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
import ReportNavigatorBlock from './blocks/report-navigator.block';

export type ReportOutletContext = {
  chartModalIsOpen: boolean;
  changeIsOpenChartModal: (status: boolean) => void;
  metricList: MetricListArrType[] | undefined;
  liveReport: LiveReport | undefined;
  currentTemplate: 'chat' | 'voice';
};

const ReportOutlet: FC<{ currentTemplate: 'chat' | 'voice' }> = ({ currentTemplate }) => {
  const { t } = useTranslation('pages.reports');
  const { id } = useParams();
  const [chartModalIsOpen, changeIsOpenChartModal] = useState<boolean>(false);
  //api
  const { currentData: liveReportVoice, isFetching: liveVoiceReportLoading } =
    useGetLiveReportSettingsQuery({ id: id || '' }, { skip: currentTemplate === 'chat' || !id });

  const { currentData: liveReportChat, isFetching: liveChatReportLoading } =
    useGetChatLiveReportSettingsQuery(
      { id: id || '' },
      { skip: currentTemplate === 'voice' || !id },
    );

  const liveReportLoading = liveChatReportLoading || liveVoiceReportLoading;
  const liveReport = { chat: liveReportChat, voice: liveReportVoice };

  const { data: voiceMetricList, isFetching: voiceProjectMetricLoading } =
    useGetProjectMetricsListQuery(
      {
        project_id: liveReport[currentTemplate || 'voice']?.project.project_id || '',
      },
      {
        skip:
          currentTemplate === 'chat' || !liveReport[currentTemplate || 'voice']?.project.project_id,
      },
    );

  const { data: chatMetricList, isFetching: chatProjectMetricLoading } =
    useGetChatProjectMetricsListQuery(
      {
        project_id: liveReport[currentTemplate || 'voice']?.project.project_id || '',
      },
      {
        skip:
          currentTemplate === 'voice' ||
          !liveReport[currentTemplate || 'voice']?.project.project_id,
      },
    );

  const metricList = { chat: chatMetricList, voice: voiceMetricList };
  const projectMetricLoading = chatProjectMetricLoading || voiceProjectMetricLoading;

  if (liveReportLoading || projectMetricLoading) return <SkeletonPageLoading />;
  if (!liveReport) return <NotFoundedPage text={t('not_found')} icon="BrokenDocIcon" />;

  return (
    <div>
      <ReportHeaderWithStatusBlock
        currentTemplate={currentTemplate}
        liveReport={liveReport[currentTemplate || 'voice']}
      />
      <ReportNavigatorBlock
        changeIsOpenChartModal={changeIsOpenChartModal}
        liveReport={liveReport[currentTemplate || 'voice']}
        currentTemplate={currentTemplate}
      />
      <Outlet
        context={
          {
            metricList: metricList[currentTemplate || 'voice'],
            liveReport: liveReport[currentTemplate || 'voice'],
            chartModalIsOpen,
            changeIsOpenChartModal,
            currentTemplate,
          } as unknown as ReportOutletContext
        }
      />
    </div>
  );
};

export default ReportOutlet;
