import React, { FC, useState } from 'react';
import cn from 'classnames';

import { ChevronLeftIcon } from '@icons';
import { menuBarData } from '@app/constants/menu-bar-data';

import useTranslation from '@app/hooks/use-translation';

import { TemplatesType } from '@app/hooks/use-project-template';

import MenuBarElement from './menu-bar-element';

import {
  collapseBarClassName,
  collapsedButtonClassName,
  // gridButtonClassName,
  menuBarClassName,
} from './menu-bar.styles';

const BAR_COLLAPSED = 'BAR_COLLAPSED';
type MenuBarPropsType = {
  currentTemplate: TemplatesType;
};
const MenuBar: FC<MenuBarPropsType> = (props) => {
  const { currentTemplate } = props;
  // const [openSecondBar, changeOpenSecondBar] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    Boolean(Number(localStorage.getItem(BAR_COLLAPSED))),
  );
  const { t } = useTranslation('components.menuBar');
  const data = menuBarData(currentTemplate || 'voice').map((item) => ({
    ...item,
    title: t(item.title as string),
    menuItems: item.menuItems.map((item) => ({ ...item, name: t(item.name) })),
  }));

  const renderMenuList = data?.map((list) => {
    const items = list.menuItems.map((item) => (
      <MenuBarElement
        key={item.id}
        itemMenu={item}
        soon={item.soon}
        isCollapsed={isCollapsed}
        tag={item.tag}
      />
    ));
    return (
      <div key={list.id} className={cn(!isCollapsed && 'px-[24px]', 'w-full')}>
        {isCollapsed ? (
          <div className="w-full bg-bg_4 h-[1px]" />
        ) : list.menuItems.length ? (
          <h3
            className={cn(
              list.title && 'mt-[25px]',
              'text-xs mb-[9px] font-[600] text-3color uppercase',
            )}
          >
            {list.title}
          </h3>
        ) : null}
        <ul>{items}</ul>
      </div>
    );
  });

  const renderCollapsedButtonIcon = (
    <ChevronLeftIcon size={6} className={cn(isCollapsed && 'rotate-180', 'text-1color')} />
  );

  function handleCollapsedToggle() {
    setIsCollapsed((prevState) => !prevState);
    localStorage.setItem('BAR_COLLAPSED', String(Number(!isCollapsed)));
  }

  // function handlerOnCloseBar() {
  //   changeOpenSecondBar(false);
  // }

  return (
    <aside className={menuBarClassName({ isCollapsed })}>
      {/* <MenuSecondBar isOpen={openSecondBar} onClose={handlerOnCloseBar} /> */}
      <div className="inline-flex w-full flex-col h-full justify-between">
        <div className="h-full overflow-y-auto overflow-x-hidden w-full">{renderMenuList}</div>
        <div
          className={cn(
            'inline-flex mt-[10px]',
            isCollapsed ? 'flex-col-reverse h-[90px]' : 'items-end justify-end',
          )}
        >
          {/* <div className={gridButtonClassName} onClick={changeOpenSecondBar.bind(null, true)}>
            <GridIcon size={15} className="text-white" />
          </div> */}
          <div className={collapsedButtonClassName(isCollapsed)}>
            <div className={collapseBarClassName} onClick={handleCollapsedToggle.bind(null)}>
              {renderCollapsedButtonIcon}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default MenuBar;
