import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromAll } from '@app/pages/email-confirmation';

export const unsubscribeFromAllRoute = (
  <Route path={navigationRoutes.unsubscribeFromAll}>
    <Route index element={<UnsubscribeFromAll />} />
  </Route>
);
