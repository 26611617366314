import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { SubscribeError } from '@app/pages/email-confirmation';

export const subscribeErrorRoute = (
  <Route path={navigationRoutes.subscribeError}>
    <Route index element={<SubscribeError />} />
  </Route>
);
