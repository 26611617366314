import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Select, Input, CircleButton, Skeleton } from '@ui';
import { useSetValueTagMetricMutation } from '@app/store/api/metrics.api';
import { toast } from 'react-toastify';
import useOnClickOutside from '@app/hooks/use-onclick-outside';
import { SettingsMetricType } from '@app/interfaces/pages-types/anatylics-metric.type';

import Icon from '@app/components/ui/icons';

import useTranslation from '@app/hooks/use-translation';

import { pictogramsData } from './pictograms.data';

type MetricEditValueProps = {
  settings: SettingsMetricType | null;
  project_id: string;
  metric_id: string;
  record_id: string;
  currentValue: number | string | string[] | number[];
  onCancel?: () => void;
  onChanged?: () => void;
  valueType?: string;
  valueMaxWidth?: number;
  columnWidth?: number;
  withPadding?: boolean;
  index?: number;
  limit?: number;
  page?: number;
  visualization?: 'native' | 'mix' | 'icons';
};
const MetricEditValue: FC<MetricEditValueProps> = React.memo((props) => {
  const {
    currentValue,
    valueType,
    settings,
    page,
    project_id,
    metric_id,
    withPadding = true,
    record_id,
    onChanged,
    onCancel,
    valueMaxWidth,
    columnWidth,
    visualization,
    limit,
    index,
  } = props;
  const { t } = useTranslation('pages.metricTemplates.manualMetric');

  const [editMode, changeEditMode] = useState<boolean>(false);
  const [value, changeValue] = useState<string[] | number[] | string | number>(currentValue);
  const [putUpdateValue, { isLoading }] = useSetValueTagMetricMutation();
  const editBlockRef = useRef<HTMLDivElement>(null);
  const activeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    changeValue(currentValue);
  }, [currentValue]);
  useOnClickOutside(editBlockRef, () => {
    changeEditMode(false);
    changeSelectedStyleToggle(false);
    changeValue(currentValue);
    onCancel?.();
  });

  const selectOptions = useMemo(
    () =>
      settings?.select?.map((selectItem) => ({
        title: visualization === 'icons' ? selectItem.icon : selectItem.value,
        value: selectItem.id,
        icon: visualization === 'mix' ? pictogramsData[selectItem.icon] : undefined,
      })) || [],
    [settings?.select, visualization],
  );
  function changeSelectedStyleToggle(isActive: boolean) {
    if (activeRef?.current?.style) {
      activeRef.current.style['position'] = isActive ? 'absolute' : 'block';
    }
  }

  function saveValueHandler() {
    putUpdateValue({ params: { project_id, metric_id, record_id }, body: { value } })
      .unwrap()
      .then(() => {
        changeEditMode(false);
        toast.success(t('toast_update_success_message'));
        onChanged?.();
      })
      .catch((error) => {
        console.error(error);
        toast.error(t('toast_update_error_message'));
      });
  }
  const conditionalSelectOnChange = (condition: boolean) => {
    return !condition
      ? {
          onChange: (val) => {
            changeValue([val]);
          },
        }
      : {
          onChangeMultiple: (val) => {
            changeValue(val);
          },
        };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  function selectDefaultValueFind() {
    if (!settings?.select) return;
    if (typeof value !== ('string' || 'number') && value !== undefined && value !== null)
      return settings.multiselect ? value : value?.[0];
    const defaultValue = (value as string)
      ?.split(',')
      .map((w) =>
        settings.multiselect
          ? /[a-zA-ZА-ЯаяёЁ]/gim.test(w)
            ? w.trim()
            : String(parseFloat(w)).trim()
          : /[a-zA-ZА-ЯаяёЁ]/gim.test(w)
          ? w.trim()
          : parseFloat(w),
      );
    const values: number[] = [];
    for (const option of settings.select) {
      if (
        defaultValue?.includes(
          settings.multiselect
            ? String(option.value)
            : /[a-zA-ZА-ЯаяёЁ]/gim.test(option.value.toString())
            ? option.value
            : +option.value,
        )
      ) {
        values.push(option.id);
      }
    }
    return settings?.multiselect ? values : values[0];
  }

  const handleDropTop = (function () {
    if (!limit || !index || !page) return false;
    const position = Math.ceil(index / page);
    const flag = limit - 4;
    return position >= flag;
  })();
  const selectDefaultValue = settings?.multiselect
    ? selectDefaultValueFind().length
      ? selectDefaultValueFind()
      : [settings?.default?.value]
    : selectDefaultValueFind()
    ? selectDefaultValueFind()
    : settings?.default?.value;
  const valueTypesList = {
    select: (
      <div style={{ maxWidth: columnWidth ? columnWidth * 20 : valueMaxWidth + 'px' }}>
        <Select
          withoutPadding
          size={columnWidth ? columnWidth * 20 : valueMaxWidth}
          options={selectOptions}
          defaultValue={selectDefaultValue}
          {...conditionalSelectOnChange(settings?.multiselect || false)}
          dropWidth={columnWidth ? columnWidth * 19 : valueMaxWidth}
          isMulti={settings?.multiselect}
          dropTop={handleDropTop}
          pictogramsMode={visualization === 'icons'}
        />
      </div>
    ),
    input: (
      <Input
        onChange={(event) => {
          valueType === 'num' ? changeValue(+event.target.value) : changeValue(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            saveValueHandler();
          }
        }}
        type={valueType === 'num' ? 'number' : 'text'}
        defaultValue={String(value ?? (settings?.default?.value || ''))}
      />
    ),
  };
  const valueCreator = {
    select:
      settings?.input === 'select' && visualization === 'native' ? (
        <div
          style={{ maxWidth: `${columnWidth ? columnWidth * 20 : valueMaxWidth}px` }}
          className="truncate"
        >
          {value || settings.select?.find((value) => value.id === settings.default?.value)?.value}
        </div>
      ) : visualization === 'icons' ? (
        settings?.multiselect ? (
          settings?.select
            ?.filter(
              (i) => selectDefaultValueFind().includes(i.id) || settings.default?.value === i.id,
            )
            .map((item) => (
              <Icon
                key={item.id}
                name={pictogramsData?.[item.icon]}
                className="text-action"
                size={30}
              />
            ))
        ) : (
          <Icon
            name={
              pictogramsData?.[
                settings?.select?.find(
                  (item) => item.id === (selectDefaultValueFind() || settings.default?.value),
                )?.icon as number
              ]
            }
            className="text-action"
            size={30}
          />
        )
      ) : (
        <div
          style={{ maxWidth: columnWidth ? columnWidth * 20 : valueMaxWidth + 'px' }}
          className=" h-[40px] flex items-center"
        >
          {settings?.multiselect ? (
            settings?.select
              ?.filter(
                (i) => selectDefaultValueFind().includes(i.id) || settings.default?.value === i.id,
              )
              .map((item) => (
                <div key={item.id} className="flex items-center">
                  <Icon name={pictogramsData?.[item.icon]} className="text-action" size={30} />
                  <div>{item.value}</div>
                </div>
              ))
          ) : (
            <div className="flex items-center">
              <Icon
                name={
                  pictogramsData?.[
                    `${
                      settings?.select?.find(
                        (item) => item.id === (selectDefaultValueFind() || settings.default?.value),
                      )?.icon
                    }`
                  ]
                }
                className="text-action"
                size={30}
              />
              <div>
                {value ||
                  settings?.select?.find((value) => value.id === settings.default?.value)?.value}
              </div>
            </div>
          )}
        </div>
      ),
    input: value || settings?.default?.value,
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton height={34} width="100%" />
      </div>
    );
  return (
    <div>
      {editMode && settings?.input ? (
        <div
          ref={editBlockRef}
          className="flex gap-[10px] items-center justify-center relative w-fit"
        >
          <div className="w-full mt-[3px]">{valueTypesList[settings.input]}</div>
          <CircleButton
            icon="CheckIcon"
            className="text-white bg-action p-[3px] rounded-[8px]"
            size={15}
            onClick={saveValueHandler.bind(null)}
          />
        </div>
      ) : (
        <div className={`flex items-center gap-[10px] ${withPadding && 'px-[20px]'}`}>
          <div
            style={{ maxWidth: columnWidth ? columnWidth * 20 : valueMaxWidth + 'px' }}
            className="flex truncate !text-[14px] items-center h-[40px]"
          >
            {valueCreator[String(settings?.input)] || value}
          </div>
          <CircleButton icon="EditIcon" size={15} onClick={changeEditMode.bind(null, true)} />
        </div>
      )}
    </div>
  );
});
MetricEditValue.displayName = 'MetricEditValue';
export default MetricEditValue;
