import { useState } from 'react';

export type TemplatesType = 'chat' | 'voice';
const storageKey = 'PROJECT_TEMPLATE';

function useProjectTemplate(callBack?: (template: TemplatesType) => void) {
  const [currentTemplate, setTemplate] = useState<TemplatesType>(
    (localStorage.getItem(storageKey) as TemplatesType) || 'voice',
  );

  function changeTemplateHandler(template: TemplatesType) {
    localStorage.setItem(storageKey, template);
    callBack?.(template);
    console.log('ooopa,', template);

    setTemplate(template);
  }

  return { currentTemplate, changeTemplateHandler };
}
export default useProjectTemplate;
