import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { DownloadCloudIcon, Oscilogram } from '@icons';
import useTranslation from '@app/hooks/use-translation';
import {
  useGetMetricRecordsChatQuery,
  useGetMetricRecordsQuery,
} from '@app/store/api/metric-records.api';
import { Loading, Paginator, Skeleton } from '@ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { useLazyDownloadRecordQuery } from '@app/store/api/records.api';
import { GetFilterParams } from '@app/pages/last-project-records/last-project-records.page';
import { FilterTableData } from '@app/components/table/table.type';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { useAppSelector } from '@app/store/store';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import Table from '@app/components/table';
import MetricTableValueBuild from '@app/components/metric-table-value-build';
import { MetricHeaderItem } from '@app/interfaces/metric.type';
import { useLazyGetProjectMetricQuery } from '@app/store/api/metrics.api';
import { v4 } from 'uuid';
import useProjectTemplate from '@app/hooks/use-project-template';

type TableMetricRecordsProps = {
  project_id: string;
  filterParams: GetFilterParams;
  changeFilterParams: React.Dispatch<React.SetStateAction<GetFilterParams>>;
};

const TableMetricRecords: FC<TableMetricRecordsProps> = React.memo((props) => {
  const { t } = useTranslation('pages.recordsByAnalytics');
  const { project_id, filterParams, changeFilterParams } = props;
  const tableUtilIconsClassName = classNames(
    'text-3color transition hover:text-action cursor-pointer transition cursor-pointer',
  );
  const [metricSettings, updateMetricSettings] = useState<{
    [metric_id: string]: MetricHeaderItem;
  }>({});
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [reportRecordsOrderBy, changeRecordsOrderBy] = useState<FilterTableData>();
  const [tableLayouts, changeTableLayouts] = useState(
    userSettings?.tableColsSize?.lastProjectRecords,
  );
  const { currentTemplate } = useProjectTemplate();
  const { data: voiceMetricRecords, isLoading: voiceLoading } = useGetMetricRecordsQuery(
    {
      ...{
        ...filterParams,
        sortBy: reportRecordsOrderBy?.sortBy,
        sortDesc: reportRecordsOrderBy?.sortDesc,
      },
      project_id: project_id,
    },
    { skip: currentTemplate === 'chat' || !project_id },
  );
  const { data: chatMetricRecords, isLoading: chatLoading } = useGetMetricRecordsChatQuery(
    {
      ...{
        ...filterParams,
        sortBy: reportRecordsOrderBy?.sortBy,
        sortDesc: reportRecordsOrderBy?.sortDesc,
      },
      project_id: project_id,
    },
    { skip: currentTemplate === 'voice' || !project_id },
  );
  const data = useMemo(
    () => ({
      chat: chatMetricRecords,
      voice: voiceMetricRecords,
    }),
    [chatMetricRecords, voiceMetricRecords],
  );
  const isLoading = voiceLoading || chatLoading;
  const [getProjectMetric, { isLoading: metricLoading }] = useLazyGetProjectMetricQuery();
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  const [downloadRecord] = useLazyDownloadRecordQuery();
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 500);
    }
  }, [userLoading]);
  const checkingAliasForSorting = useCallback(
    (index: number, condition: boolean) => {
      if (condition) return false;
      return index !== (data[currentTemplate || 'voice']?.headers.length as number) + 1;
    },
    [currentTemplate, data],
  );
  useEffect(() => {
    if (!data || isLoading) return;
    updateMetricSettings(
      (data[currentTemplate || 'voice']?.headers || []).reduce(
        (metricHeaderResult, currentMetric) => {
          metricHeaderResult[currentMetric.metric_id] = currentMetric;
          if (currentMetric.type === 'tags') {
            getProjectMetric({
              project_id,
              metric_id: currentMetric.metric_id,
            })
              .unwrap()
              .then((metricInfoResult) =>
                updateMetricSettings((prev) => ({
                  ...prev,
                  [currentMetric.metric_id]: {
                    ...prev[currentMetric.metric_id],
                    settings: metricInfoResult.settings,
                  },
                })),
              );
          }
          return metricHeaderResult;
        },
        {},
      ),
    );
  }, [currentTemplate, data, getProjectMetric, isLoading, project_id]);

  const layout = useMemo(
    () =>
      tableLayouts
        ?.find((layoutItem) => layoutItem.id === project_id)
        ?.layout.map((item) => ({ ...item, maxW: 16 })),
    [project_id, tableLayouts],
  );

  const tableColumns = useMemo(
    () => [
      {
        index: 'duration_project_records_items',
        title: t('table.first_column_title'),
        size: 160,
        hintTitle: t('popup_hints.to_record_title'),
      },
      // ?.sort(
      //   (a, b) =>
      //     (layout?.find((item) => item.i === a.metric_id)?.x as number) -
      //     (layout?.find((item) => item.i === b.metric_id)?.x as number),
      // )

      ...(data[currentTemplate || 'voice']?.headers?.map((header, index) => ({
        title: header.name,
        index: header.metric_id,
        size: 190,
        truncate: true,
        disableDefaultHeight: header.type === 'tags',
        filter: checkingAliasForSorting(
          index,
          header.type === 'tags'
            ? header.input === 'select' && header.multiselect === 'True'
            : false,
        ),
      })) || []),
      { index: 'actions_project_records_items', title: t('table.last_column_title'), size: 110 },
    ],
    [checkingAliasForSorting, currentTemplate, data, t],
  );

  const tableData = useMemo(
    () =>
      data[currentTemplate || 'voice']?.metrics.length
        ? data[currentTemplate || 'voice']?.metrics?.map((item, index) => ({
            ...item,
            duration_project_records_items: (
              <div className="flex cursor-pointer">
                <TimeDuration
                  link={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
                  duration={item.duration}
                />
              </div>
            ),
            actions_project_records_items: (
              <div className="flex items-center pl-[19px]">
                <DownloadCloudIcon
                  hintTitle={t('popup_hints.download_record')}
                  size={18}
                  className={`${tableUtilIconsClassName}, mr-[18px]`}
                  onClick={() => downloadRecord({ record_id: item.record_id })}
                />
                <Link
                  target="_blank"
                  to={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
                >
                  <Oscilogram
                    hintTitle={t('popup_hints.to_record_title')}
                    size={22}
                    className={tableUtilIconsClassName}
                  />
                </Link>
              </div>
            ),
            ...Object.fromEntries(
              item.data.map((metric) => [
                [metric.metric_id],
                item.calculated && metricSettings ? (
                  <MetricTableValueBuild
                    key={index + v4()}
                    metric={metric}
                    index={index}
                    page={Math.ceil(Number(filterParams.offset) / filterParams.limit) + 1}
                    limit={filterParams.limit}
                    recordID={item.record_id}
                    projectID={project_id}
                    metricHeader={metricSettings[metric.metric_id]}
                    columnWidth={
                      layout?.find((layoutItem) => layoutItem.i === metric.metric_id)?.w || 6
                    }
                  />
                ) : (
                  <div></div>
                ),
              ]),
            ),
          }))
        : [],
    [
      currentTemplate,
      data,
      downloadRecord,
      filterParams.limit,
      filterParams.offset,
      layout,
      metricSettings,
      project_id,
      t,
      tableUtilIconsClassName,
    ],
  );
  function changePage({ offset, limit }: PaginationResponse) {
    changeFilterParams((prev) => ({ ...prev, offset: offset, limit: limit }));
    updateUserSettings({
      ...userSettings,
      tablesLimit: { ...userSettings?.tablesLimit, lastProjectRecords: limit },
    });
  }

  useEffect(() => {
    if (!project_id) return;
    const condition = userSettings?.tableColsSize?.lastProjectRecords?.find(
      (layoutItem) => layoutItem.id === project_id,
    );
    if (!condition) {
      const systemArr = [
        ...(userSettings?.tableColsSize?.lastProjectRecords || []),
        { id: project_id, layout: [] },
      ];
      updateUserSettings({
        ...userSettings,
        tableColsSize: {
          ...userSettings?.tableColsSize,
          lastProjectRecords: systemArr,
        },
      });
    } else {
      changeTableLayouts(userSettings?.tableColsSize?.lastProjectRecords);
    }
  }, [project_id, updateUserSettings, userSettings]);

  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = tableLayouts?.map((tablesLayout) => {
      if (tablesLayout.id === project_id) {
        return { id: tablesLayout.id, layout: layout.map((item) => ({ ...item, maxW: 24 })) };
      }
      return tablesLayout;
    });

    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        lastProjectRecords: currentChangedLayout,
      },
    });
  }
  if (metricLoading) return <Loading />;
  return (
    <div className="">
      {(userSettingsLoading || isLoading || data === undefined) && (
        <Skeleton height={800} className={'absolute z-[999] top-0  mt-6'} />
      )}
      <Table
        layout={layout}
        onLayoutChange={onChangeLayoutHandler}
        columns={tableColumns}
        dataSource={tableData}
        onFilter={changeRecordsOrderBy}
      />
      <div className="my-[20px]">
        <Paginator
          page={Math.ceil(Number(filterParams.offset) / filterParams.limit) + 1}
          count={data[currentTemplate || 'voice']?.total || 0}
          limit={filterParams.limit}
          onChange={changePage}
          recordsQueue={
            (data[currentTemplate || 'voice']?.total || 0) -
            (data[currentTemplate || 'voice']?.total_calculated || 0)
          }
        />
      </div>
    </div>
  );
});
TableMetricRecords.displayName = 'TableMetricRecords';
export default TableMetricRecords;
