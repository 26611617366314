import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromReport } from '@app/pages/email-confirmation';

export const unsubscribeFromReportRoute = (
  <Route path={navigationRoutes.unsubscribeFromReport}>
    <Route index element={<UnsubscribeFromReport />} />
  </Route>
);
