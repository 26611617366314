import { chartBuildData } from '@app/components/chart/chart-build.data';
import { Box, TooltipWrapper } from '@app/components/ui';
import useChartDateIntervals, { ChartOptionDate } from '@app/hooks/use-chart-date-intervals';
import useTranslation from '@app/hooks/use-translation';
import { RecordRangeRangeParametersItemForRType } from '@app/interfaces/record';
import { ChartTypes, ReportChart } from '@app/interfaces/report.type';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import React, { ReactNode, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartComponent = ({ data }: { data: ReportChart }) => {
  const { t } = useTranslation('pages.chartPage');

  const noDataTextMap: Record<number, string> = useMemo(
    () => ({
      500: t('system.no_metric'),
      403: t('system.no_access'),
    }),
    [t],
  );
  const { daysSelectOptions, dateToString } = useChartDateIntervals();
  const rangeMarkup: Record<ChartOptionDate, ReactNode> = {
    all: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'all')?.title}</div>,
    day: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'day')?.title}</div>,
    week: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'week')?.title}</div>,
    month: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'month')?.title}</div>,
    today: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'today')?.title}</div>,
    currentWeek: (
      <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentWeek')?.title}</div>
    ),
    currentMonth: (
      <div>
        {daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentMonth')?.title}
      </div>
    ),
    customDate: (
      <div className="flex items-center gap-[10px]">
        <div className="flex items-center gap-[5px]">
          {(data.range?.parameters as unknown as RecordRangeRangeParametersItemForRType[]).map(
            (rangeItem, index) => (
              <div key={index}>
                {index === 0 ? t('interval_under_label.from') : t('interval_under_label.to')} &nbsp;
                {moment(rangeItem.value).format('DD.MM.YYYY  HH:mm')}
              </div>
            ),
          )}
        </div>
      </div>
    ),
  };
  const currentChartBuild = useMemo(
    () =>
      chartBuildData({
        chart: data,
        showToolbar: true,
        noDataText: data.errorStatus
          ? { text: noDataTextMap[data.errorStatus] }
          : { text: t('system.no_data') },
        tooltipDynamicOffset: 10,
        tooltipCustomOffset: -24,
        translationList: {
          download: t('chart_toolbar.download'),
          selectionZoom: t('chart_toolbar.selectionZoom'),
          zoomIn: t('chart_toolbar.zoomIn'),
          zoomOut: t('chart_toolbar.zoomOut'),
          pan: t('chart_toolbar.pan'),
          reset: t('chart_toolbar.reset'),
        },
      }),
    [data, noDataTextMap, t],
  );
  const chart = useMemo(
    () => (
      <ReactApexChart
        key={currentChartBuild.options.chart.id}
        options={currentChartBuild.options as unknown as ApexOptions}
        series={currentChartBuild.series}
        width="100%"
        height="90%"
        type={(currentChartBuild.options?.chart?.type as ChartTypes) || 'bar'}
      />
    ),
    [currentChartBuild.options, currentChartBuild.series],
  );
  console.log(currentChartBuild, 'chart');

  if (!data) return <div>her</div>;
  return (
    <Box
      className={`${
        data.errorStatus && '!bg-basic_red/[.4]'
      } relative min-w-full h-full pt-4 pb-16`}
    >
      <div className="h-full">
        <div className="flex items-start justify-between pl-[20px] w-full">
          <div className="flex flex-col w-full gap-[5px]">
            <div className="flex items-center justify-between w-full">
              <div>
                <TooltipWrapper content={data?.name} id={data?.name + data.graph_id}>
                  <h2 style={{ fontSize: 18 + 'px' }} className=" truncate text-1color font-[700]">
                    {data?.name}
                  </h2>
                </TooltipWrapper>
              </div>
            </div>
            {/* (chartWidth || 0) > 230 */}
            {data ? (
              <div className="w-full h-full">
                {/* <DropMenu
              dropHeight={
                (chartHeight || 0) - 100 > 70
                  ? (chartHeight || 0) > 350
                    ? 280
                    : (chartHeight || 0) - 100
                  : 55
              }
              dropSize={210}
              onClick={(key) => handlerChangeChartDateSelect(key)}
              menu={daysSelectOptions.map((item) => ({
                ...item,
                isActive: dateToString(data?.range) === item.key,
              }))}
              position="start"
            > */}
                <div className="noDrag text-4color font-[400]  leading-[10px] tracking-tight text-[12px]">
                  {rangeMarkup[dateToString(data?.range)]}
                </div>
                {/* </DropMenu> */}
              </div>
            ) : null}
          </div>
        </div>

        <div className="w-[95%] h-[99%]">
          <div className="h-full w-full">{chart}</div>
        </div>
      </div>
    </Box>
  );
};

export default ChartComponent;
