import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  CreateNewReportGraphType,
  NewLiveReportGraph,
  ReportChart,
  ReportListChart,
} from '@app/interfaces/report.type';

import {
  addNewChartOnReport,
  deleteChartFromReport,
  editChartOnReport,
  setInitialReportCharts,
} from '@app/store/reducers/chat-report-charts.slice';

import { EditGraphLayoutType } from '@app/interfaces/dashboards.type';

import { baseQuery } from '../../baseQuery';
import { liveReportsChartsApi } from '../live-reports-charts.api';

export const chatLiveReportsChartsApi = createApi({
  reducerPath: 'chatLiveReportsChartsApi',
  tagTypes: ['singleLiveReportChart', 'reportCharts'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getChatLiveReportsGraphList: build.query<(ReportListChart & ReportChart)[], string | undefined>(
      {
        async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
          const graphsArray: (ReportListChart & ReportChart)[] = [];
          const graphsInLiveReport = (await fetchWithBQ({
            url: serverRoutes.chatCharts,
            params: { live_report_id: arg },
          })) as unknown as { data: { live_report_graph_list: ReportListChart[] } };
          const data = graphsInLiveReport.data.live_report_graph_list || [];
          for (const graph of data) {
            const graphData = (await fetchWithBQ({
              url: serverRoutes.chartData,
              method: 'POST',
              body: { id: graph.graph_id },
            })) as unknown as {
              data: { data: ReportChart };
              error?: {
                status: number;
                originalStatus: number;
                data: {
                  data: ReportChart;
                };
              };
            };
            if (graphData.error?.status) {
              switch (
                typeof graphData.error?.status === 'number'
                  ? graphData.error.status
                  : graphData.error.originalStatus
              ) {
                case 500:
                  graphsArray.push({
                    ...(graphData.error.data.data !== undefined
                      ? graphData.error.data.data
                      : {
                          ...graphData?.data?.data,
                          type: 'barchart',
                          errorStatus: graphData.error?.status,
                          graph_id: graph.graph_id,
                          name: 'error',
                          dataLabels: false,
                          categories: [],
                          series: [],
                          range: { type: 'l', parameters: [{ num: 7 }] },
                          // order_by: OrderByChartSettings;
                          line_settings: { active: false, color: '', value: [] },
                          styles: {
                            header_size: 24,
                            text_size: 12,
                            value_position: 'inside',
                            grid: false,
                            text_color: '#000',
                            legend_position: 'bottom',
                          },
                          live_report_id: arg,
                          layout: { ...graph.layout, i: graph.graph_id },
                        }),
                    layout: { ...graph.layout, i: graph.graph_id },
                    errorStatus: graphData.error?.status,
                  });
                  break;
                case 403:
                  graphsArray.push({
                    ...graphData?.data?.data,
                    type: 'barchart',
                    errorStatus: graphData.error?.status,
                    graph_id: graph.graph_id,
                    name: 'error',
                    dataLabels: false,
                    categories: [],
                    series: [],
                    range: { type: 'l', parameters: [{ num: 7 }] },
                    // order_by: OrderByChartSettings;
                    line_settings: { active: false, color: '', value: [] },
                    styles: {
                      header_size: 24,
                      text_size: 12,
                      value_position: 'inside',
                      grid: false,
                      text_color: '#000',
                      legend_position: 'bottom',
                    },
                    live_report_id: arg,
                    layout: { ...graph.layout, i: graph.graph_id },
                  });
                  break;
                case 504:
                  graphsArray.push({
                    ...graphData?.data?.data,
                    type: 'barchart',
                    errorStatus: graphData.error?.status,
                    graph_id: graph.graph_id,
                    name: 'error',
                    dataLabels: false,
                    categories: [],
                    series: [],
                    range: { type: 'l', parameters: [{ num: 7 }] },
                    // order_by: OrderByChartSettings;
                    line_settings: { active: false, color: '', value: [] },
                    styles: {
                      header_size: 24,
                      text_size: 12,
                      value_position: 'inside',
                      grid: false,
                      text_color: '#000',
                      legend_position: 'bottom',
                    },
                    live_report_id: arg,
                    layout: { ...graph.layout, i: graph.graph_id },
                  });
                  break;
                default:
                  break;
              }
            } else {
              graphData.data &&
                graphsArray.push({
                  ...graphData.data.data,
                  live_report_id: arg,
                  layout: { ...graph.layout, i: graph.graph_id },
                });
            }
          }
          return graphsArray
            ? { data: graphsArray as (ReportListChart & ReportChart)[] }
            : { error: { error: '123', data: [] } as FetchBaseQueryError };
        },
        providesTags: ['reportCharts'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setInitialReportCharts({ data, type: 'initialSetup' }));
          } catch (error) {
            console.error(error);
          }
        },
      },
    ),

    getLiveReportGraph: build.query<NewLiveReportGraph, { params: { id: string } }>({
      query: ({ params }) => ({
        url: serverRoutes.chatChart,
        params,
      }),
      providesTags: ['singleLiveReportChart'],
    }),
    addNewChatLiveReportGraph: build.mutation<
      {
        createdChartOnReport: NewLiveReportGraph;
        createdChartData: ReportChart;
      },
      CreateNewReportGraphType
    >({
      async queryFn(arg, _, extraOptions, fetchWithBQ) {
        const createGraphData = (await fetchWithBQ({
          url: serverRoutes.chatChart,
          method: 'POST',
          body: arg,
        })) as unknown as { data: NewLiveReportGraph };
        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: createGraphData.data.graph_id },
        })) as unknown as { data: { data: ReportChart } };
        // const formattedChartObject = {
        //   ...graphData.data.data,
        //   layout: { ...createGraphData.data.layout, i: createGraphData.data.graph_id },
        //   live_report_id: arg.live_report_id,
        // };
        return createGraphData
          ? {
              data: {
                createdChartOnReport: createGraphData.data,
                createdChartData: graphData.data.data,
              },
            }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { createdChartOnReport, createdChartData },
          } = await queryFulfilled;
          dispatch(addNewChartOnReport({ createdChartOnReport, createdChartData }));
        } catch (error) {
          dispatch(liveReportsChartsApi.util.invalidateTags(['reportCharts']));
          console.error(error);
        }
      },
    }),
    editNewChatLiveReportGraph: build.mutation<
      {
        editedChartOnReport: NewLiveReportGraph;
        editedChartData: ReportChart;
      },
      { params: { id: string }; body: CreateNewReportGraphType; liveReportId?: string }
    >({
      async queryFn({ body, params }, _, extraOptions, fetchWithBQ) {
        const editGraphData = (await fetchWithBQ({
          url: serverRoutes.chatChart,
          method: 'PUT',
          body,
          params,
        })) as unknown as { data: NewLiveReportGraph };
        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: editGraphData.data.graph_id },
        })) as unknown as { data: { data: ReportChart } };

        // const formattedChartObject = {
        //   ...graphData.data.data,
        //   layout: { ...editGraphData.data.layout, i: editGraphData.data.graph_id },
        // };
        return editGraphData
          ? {
              data: {
                editedChartOnReport: editGraphData.data,
                editedChartData: graphData.data.data,
              },
            }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      invalidatesTags: ['singleLiveReportChart'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { editedChartOnReport, editedChartData },
          } = await queryFulfilled;
          dispatch(editChartOnReport({ editedChartOnReport, editedChartData }));
        } catch (error) {
          dispatch(liveReportsChartsApi.util.invalidateTags(['reportCharts']));
          console.error(error);
        }
      },
    }),
    deleteNewChatLiveReportGraph: build.mutation({
      query: (params: { id: string; live_report_id?: string }) => ({
        url: serverRoutes.chatChart,
        method: 'DELETE',
        params,
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded;
        dispatch(deleteChartFromReport(arg.id));
      },
    }),
    // layout
    editChatLiveReportGraphLayout: build.mutation<
      NewLiveReportGraph,
      { body: EditGraphLayoutType[]; liveReportId: string }
    >({
      query: ({ body, liveReportId }) => ({
        url: serverRoutes.chatChartLayout,
        method: 'PUT',
        body: {
          live_report_id: liveReportId,
          layout: {
            live_report_graph_order: body,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetChatLiveReportsGraphListQuery,
  useEditNewChatLiveReportGraphMutation,
  useAddNewChatLiveReportGraphMutation,
} = chatLiveReportsChartsApi;
