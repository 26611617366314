import React, { FC, useState } from 'react';

import { Button, EasyModal, PermissionAccessWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';

import {
  useAddChannelMutation,
  useDeleteChannelMutation,
  useEditChannelMutation,
  useGetCommonSettingsQuery,
  useLazyGetChannelQuery,
} from '@app/store/api/common-settings-page.api';

import {
  useGetChatRolesQuery,
  useCreateChatRolesMutation,
  useEditChatRoleMutation,
  useDeleteChatRoleMutation,
} from '@app/store/api/chat-api/get-chat-roles.api';
2;

import { ChannelSettingObject } from '@app/interfaces/settings.type';

import useProjectTemplate from '@app/hooks/use-project-template';

import ChatRolesTableBlock from '@app/pages/settings/blocks/common-settings/chat-roles-table.block';

import { ChatModalWithForm } from '@app/pages/settings/blocks/common-settings/chat.modal-with-fom';

import { CreateRoleType, ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

import { toast } from 'react-toastify';

import { userPermissions } from '@app/utils/user-permissions';

import ChannelsTableBlock from './blocks/common-settings/channels-table.block';
import { ModalWithForm } from './blocks/common-settings/modal-with-form';

const CommonSettingsPage: FC = () => {
  const { currentTemplate } = useProjectTemplate();
  console.log('currentTemplate', currentTemplate);
  const { t } = useTranslation('pages.settings');
  const { data, isLoading } = useGetCommonSettingsQuery();
  const { data: chatRoleBulk } = useGetChatRolesQuery();

  const [createNewChannel] = useAddChannelMutation();
  const [createNewRole] = useCreateChatRolesMutation();
  const [editRole] = useEditChatRoleMutation();
  const [deleteRole] = useDeleteChatRoleMutation();
  const [editChannel] = useEditChannelMutation();
  const [getChannel] = useLazyGetChannelQuery();
  const [isOpenAddChannelModal, changeOpenAddChannelModal] = useState<boolean>(false);
  const [isOpenAddChatRoleModal, changeOpenAddChatRoleModal] = useState<boolean>(false);
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState<boolean>(false);
  const [channelIdForDelete, changeChannelIdForDelete] = useState<{ isRole: boolean; id: string }>({
    isRole: false,
    id: '',
  });
  const [editMode, changeEditMode] = useState<boolean>(false);
  const [channelData, changeChannelData] = useState<ChannelSettingObject | undefined>();
  const [chatData, changeChatData] = useState<ChatRoleSettingsType | undefined>();

  const [deleteChannel] = useDeleteChannelMutation();
  function deleteHandleClick(id: string, isRole = false) {
    changeChannelIdForDelete({ id, isRole });
    changeOpenDeleteModal(true);
  }

  function handleRemoveRoleOrChannel() {
    if (!channelIdForDelete.id) return;
    if (channelIdForDelete.isRole) {
      deleteRole({ id: channelIdForDelete.id });
    } else deleteChannel(channelIdForDelete.id);
  }

  function editHandleClick(id: string) {
    getChannel(id).then((data) => {
      changeChannelData(data.data);
      changeEditMode(true);
      changeOpenAddChannelModal(true);
    });
  }

  function editHandleChatClick(crole_id: string) {
    const roleToEdit = chatRoleBulk?.find((role) => role.crole_id === crole_id);
    if (roleToEdit) {
      changeChatData(roleToEdit);
      changeEditMode(true);
      changeOpenAddChatRoleModal(true);
    }
  }
  function onFormSubmitHandler(data: ChannelSettingObject) {
    editMode ? editChannel(data) : createNewChannel(data);
    changeOpenAddChannelModal((prev) => !prev);
  }

  function onFormSubmitChatHandler(data: CreateRoleType) {
    if (editMode && chatData) {
      editRole({ body: { ...data, crole_id: chatData.crole_id } })
        .unwrap()
        .then(() => {
          toast.success(t('common_setting.chatRoles.toast.role_edit'));
        })
        .catch((error) => {
          toast.error(`${t('common_setting.chatRoles.toast.error_while_edit')} ${error.message}`);
        });
    } else {
      createNewRole({ body: data })
        .unwrap()
        .then(() => {
          toast.success(t('common_setting.chatRoles.toast.role_add'));
        })
        .catch((error) => {
          toast.error(`${t('common_setting.chatRoles.toast.error_while_add')} ${error.message}`);
        });
    }
    changeOpenAddChatRoleModal(false);
  }

  const renderModal = () => {
    if (!isOpenAddChannelModal) return;
    return (
      <ModalWithForm
        channelData={channelData}
        editMode={editMode}
        changeEditMode={changeEditMode}
        isOpenAddChannelModal={isOpenAddChannelModal}
        changeOpenAddChannelModal={changeOpenAddChannelModal}
        onFormSubmitHandler={onFormSubmitHandler}
      />
    );
  };
  const renderChatModal = () => {
    if (!isOpenAddChatRoleModal) return;
    return (
      <ChatModalWithForm
        channelData={chatData}
        editMode={editMode}
        changeEditMode={changeEditMode}
        isOpenAddChatRoleModal={isOpenAddChatRoleModal}
        changeOpenAddChatRoleModal={changeOpenAddChatRoleModal}
        onFormSubmitChatHandler={onFormSubmitChatHandler}
      />
    );
  };
  return (
    <div>
      <div className="inline-flex items-center w-full justify-between py-[30px]">
        <h1 className="text-[18px] font-[700] ml-[20px]">{t('common_setting.title')}</h1>
        <div>
          <Button
            data-id="new-channel"
            onClick={() => {
              changeOpenAddChannelModal(true);
            }}
            icon="CirclePlusIcon"
            label={t('common_setting.button.add_channel')}
          />
        </div>
      </div>
      <div>
        <ChannelsTableBlock
          onDeleteIconClick={deleteHandleClick}
          isLoading={isLoading}
          tableData={data || []}
          onEditIconClick={editHandleClick}
        />
      </div>
      {renderModal()}
      <PermissionAccessWrapper tag={userPermissions.display.displayChats} permissions="display">
        <div className="inline-flex items-center w-full justify-between py-[30px]">
          <h1 className="text-[18px] font-[700] ml-[20px]">
            {t('common_setting.chatRoles.title')}
          </h1>
          <div>
            <Button
              data-id="new-channel"
              onClick={() => {
                changeOpenAddChatRoleModal(true);
              }}
              icon="CirclePlusIcon"
              label={t('common_setting.chatRoles.add_Role')}
            />
          </div>
        </div>
        <div>
          <ChatRolesTableBlock
            onDeleteIconClick={(id: string) => deleteHandleClick(id, true)}
            isLoading={isLoading}
            tableData={chatRoleBulk || []}
            onEditIconClick={editHandleChatClick}
          />
        </div>
        {renderChatModal()}
      </PermissionAccessWrapper>
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={handleRemoveRoleOrChannel}
      >
        <div className="flex items-center justify-center">
          {t('common_setting.chatRoles.modal.delete_role_text')}
        </div>
      </EasyModal>
    </div>
  );
};

export default CommonSettingsPage;
