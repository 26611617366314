module.exports = {
  popup_hints: {
    oscillogram_view: 'Select oscillogram display format', //
    download_record: 'Download record', //
    record_play: 'Play record', //
    playing_speed: 'Playback speed', //
    download_transcript: 'Download transcript', //
    copy_link: 'Copy link',
    show_close: 'Show/hide',
    go_to: 'Go to value',
  },
  is_no_dialog: 'Dialog is absent',
  soon: 'Soon',
  recog_model: 'Recognition Model:',
  skeleton_loading_label: 'record loading...',
  record_not_found: 'Record not found',
  audio: 'Audio record',
  delete: 'Delete',
  delete_record: 'Delete record',
  record_will_deleted: 'Record will be deleted',
  audoigramm: 'Audiogram',
  metric_list: 'List of calculated metrics',
  metric: 'Metric',
  metric_value: 'Value',
  meta_data: 'Metadata',
  client: 'Client',
  operator: 'Operator',
  sec: 'sec',
  dialog: 'Dialog',
  link_copied: 'The audio link is copied!',
  translation_download_false: 'Download error',
  channels_meta_name: 'Name',
  channels_meta_channel: 'Channel',
  channels_meta_gsm: 'Location',
  metric_show_empty: 'Show empty',
  end_dialog: 'The end of dialog',
  empty_dialog: 'Record not yet recognized',
  emotions: 'emotions',
  chat_type: 'Change chat type'
};
