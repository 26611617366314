import { SET_RECORDS_ITEMS } from '@app/store/reducers/types';
import { AudioChannel } from '@app/store/reducers/audio-channels.slice';
import { VoiceEmotion } from '@app/interfaces/record-transcription';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { SettingsMetricType } from '@app/interfaces/pages-types/anatylics-metric.type';

export interface RecordReducerAction {
  type: typeof SET_RECORDS_ITEMS;
  payload: RecordsItems;
}

export type RecordTranscriptionResponse = {
  asr_model: {
    asr_model_id: string;
    asr_model_name: string;
  };
  duration: number;
  channels: {
    [channel: string]: {
      [fragment: string]: {
        nbest: {
          [x: string]: {
            final_text: string;
            final_text_proc: string;
            timing_prob: Array<{ from: number; to: number; word: string }>;
            emotions: VoiceEmotion;
          };
        };
      };
    };
  };
};

export interface RecordReducerState {
  items: RecordsItems;
  liveReportRecords: RecordLiveDataResponse<'voice'>;
  record: SingleRecord | null;
  audio: Blob | null;
  isPending: boolean;
  total: number;
  error?: string;
  recordTranscription: RecordTranscriptionResponse | null;
  compositeRecord?: RecordMutationResult;
  recordIsPending: boolean;
}

export interface RecordsItems {
  headers: RecordsItemsHeaderItem[];
  records: RecordItem[];
}

export interface RecordsItemsHeaderItem {
  alias_id: string;
  name: string;
}

export interface RecordItem {
  record_id: string;
  duration: number;
  file: RecordItemFile;
  data: RecordItemData[];
}

export interface RecordItemFile {
  external_storage: boolean;
  url: string | null;
}

export interface RecordLiveDataResponseHeader {
  metric_id: string;
  name: string;
  result_value_type: 'num' | 'str';
  type: string;
  visualization: string;
}
export interface RecordLiveDataResponseRecordsList {
  record_id: string;
  dt_start: string;
  duration: number;
  ucid: string;
  data: Array<{
    metric_id: string;
    value: string | number | null;
    visualization?: 'native' | 'score5';
  }>;
}
export type RecordLiveDataResponse<T extends 'chats' | 'voice'> = RecordLiveDataResponseCombiner[T];

type RecordLiveDataResponseCombiner = {
  chats: RecordLiveDataResponseChats;
  voice: RecordLiveDataResponseVoice;
};
export type RecordLiveDataResponseVoice = {
  headers: Array<RecordLiveDataResponseHeader>;
  records: Array<RecordLiveDataResponseRecordsList>;
  total: number;
};
export type RecordLiveDataResponseChats = {
  headers: Array<RecordLiveDataResponseHeader>;
  chats: Array<RecordLiveDataResponseRecordsList>;
  total: number;
};
export type RecordRequestData = {
  id: string;
  sortBy?: string;
  sortDesc?: boolean;
  limit: number;
  offset: number;
  filter?: Filter & Range;
};
export interface RecordItemData {
  alias_id: string;
  value: string;
}

export enum RecordActionsDropdown {
  CHANGE_FILTERED = 'change_filtered',
  CHANGE_SELECTED = 'change_selected',
  DOWNLOAD_RECORDS = 'download_records',
  DELETE_SELECTED = 'delete_selected',
}

export type SingleRecord = {
  data: Array<{ alias_id: string; value: string | number }>;
  duration: number;
  file: {
    external_storage: boolean;
    url: string | null;
  };
  record_id: string;
};

export type CompositeRecordParams = {
  projectId?: string;
  recordId: string;
  currentAsrModel?: string;
};

export type DialogChannels = { [channel_id: string]: AudioChannel };

export type RecordMutationResult = {
  wordsTimeList: WordsTimeList;
  calculatedRecordMetric: CalculatedRecordMetric;
  known_project_metrics?: Array<KnownProjectMetric>;
  known_metrics_cases?: KnownMetricsCases;
  dialog_words: Array<DialogWords>;
  dialogs: Array<Dialog>;
  dialogChannels: DialogChannels;
  emotionTrack: Array<EmotionTrack>;
  currentAsrModel: string;
  showMetricMarkup: boolean;
  recordIsRecognized: boolean;
};

export type WordsTimeList = { [id: string]: { from: number; to: number; message_id?: string } };
export type KnownProjectMetric = {
  metric_id: string;
  name: string;
  color: string;
  type: string;
  settings?: SettingsMetricType;
};
export type KnownMetricsCases = {
  [metric_id: string]: {
    cases: {
      [channel_id: string]: { [segment_id: string]: Array<{ from: number; to: number }> };
    };
    known_metric: {
      color: string;
      metric_id: string;
      name: string;
      value: string | number;
      type: string | number;
      settings?: SettingsMetricType | null;
    };
  };
};
export type EmotionTrack = VoiceEmotion & {
  from: number;
  to: number;
};
export type DialogWords = {
  channel_id: string;
  segment_id: string;
  from: number;
  to: number | null;
  word_idx: string;
  word: string;
  emotion: VoiceEmotion;
};

export type Dialog = {
  id: string;
  channel_id: string;
  segment_id: string;
  channel_config: AudioChannel;
  from: number;
  to: number;
  words: Array<DialogWords>;
  emotion: VoiceEmotion;
};

export type CalculatedRecordMetric = {
  [metricId: string]: {
    metric_id: string;
    color: string;
    type: string;
    name: string;
    value: string | number;
    settings: {
      ids: Array<string>;
      distance: Array<number>;
      messageId: Array<string | undefined>;
      other?: SettingsMetricType;
    } | null;
  };
};
