export const langData = {
  gui: {
    category_variables: {
      ru: {
        name: 'Переменные',
      },
      en: {
        name: 'Variables',
      },
    },
    category_expressions: {
      en: {
        name: 'Expressions',
      },
      ru: {
        name: 'Выражения',
      },
    },
    category_logic: {
      en: {
        name: 'Any expression (string, number, logical)/n. This block can replace other expression blocks.',
      },
      ru: {
        name: 'Любое выражение (строка, число, булевое)\nЭтот блок может заменять другие блоки выражения.',
      },
    },
  },
  blocks: {
    formula_start: {
      en: {
        help: 'This is the beginning of the formula. Anything you place outside of this block will not be used.',
      },
      ru: {
        help: 'Это начало формулы. Все, что вы разместите за пределами этого блока, не будет использовано.',
      },
    },
    formula_expression: {
      en: {
        name: '0',
        help: 'Logical functions',
      },
      ru: {
        name: '0',
        help: 'Логические функции',
      },
    },
    formula_mathops: {
      en: {
        help: 'Performs the selected mathematical operation with the given expressions.',
        button: {
          add: 'Add operation',
          remove: 'Delete operation',
        },
      },
      ru: {
        help: 'Выполняет выбранную математическую операцию с заданными выражениями.',
        button: {
          add: 'Добавить операцию',
          remove: 'Удалить операцию',
        },
      },
    },
    formula_relops: {
      en: {
        help: 'Performs the selected relational operation on the given expressions.',
      },
      ru: {
        help: 'Выполняет выбранную реляционную операцию с заданными выражениями.',
      },
    },

    /* Logic */
    formula_if: {
      en: {
        name: 'If',
        then: 'Then',
        otherwise: 'Otherwise',
        help: 'Evaluates the given logical expression and returns with a second parameter if true, or a third if false.',
      },
      ru: {
        name: 'Если',
        then: 'Тогда',
        otherwise: 'Иначе',
        help: 'Оценивает данное логическое выражение и возвращает со вторым параметром, если оно истинно, или третьим, если оно ложно.',
      },
    },
    formula_and_or: {
      en: {
        name: [
          ['and', 'AND'],
          ['or', 'OR'],
        ],
        help: 'Performs the logical AND or OR operation between given expressions.',
        button: {
          add: 'Add value',
          remove: 'Delete value',
        },
      },
      ru: {
        name: [
          ['и', 'AND'],
          ['или', 'OR'],
        ],
        help: 'Выполняет логическую операцию И или ИЛИ между заданными выражениями.',
        button: {
          add: 'Добавить значение',
          remove: 'Удалить значение',
        },
      },
    },
    formula_not: {
      ru: {
        name: 'нет',
        help: 'Изменяет результат логического выражения с ИСТИНА на ЛОЖЬ и с ЛОЖЬ на ИСТИНА.',
      },
      en: {
        name: 'not',
        help: 'Changes the result of a logical expression from True to False and from False to True.',
      },
    },
    formula_metric: {
      ru: {
        titles: 'Метрики',
        title: 'Метрика',
        empty: 'Метрика',
      },
      en: {
        titles: 'Metrics',
        title: 'Metric',
        empty: 'No metrics',
      },
    },
  },
};
