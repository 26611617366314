module.exports = {
  chapter_label: 'Триггеры',
  modal:{
    label:'Настройки триггера',
    submit_button_label:  'Сохранить',
    cancel_button_label: 'Отменить'
  },
  buttons: {
    add_trigger: 'Добавить триггер'
  },
  system: {
    warning: 'Предупреждение',
    delete_trigger_text: 'Вы действительно хотите удалиить триггер?'
  },
  form: {
    filter_label: 'Условия срабатывания триггера',
    subject: 'Название триггера',
    description: 'Описание',
    enabled: 'Статус',
    preset: 'Условия срабатывания'
  },
  table: {
    subject: 'Название',
    recipients: 'Получателей',
    preset: 'Условия срабатывания',
    actions: 'Действия'
  },
  recipients_form: {
    telegram: 'Телеграм',
    email: 'Электронная почта',
    description_field: 'Имя получателя',
    email_field: 'Почта',
    add_recipient_button: 'Добавить получателя',
    descriptionError: 'Имя получателя обязательно к заполнению',
    emailError: 'Некорректный e-mail',
    save: 'Сохранить',
    cancel: 'Отменить'
  }
}
