import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Skeleton, SkeletonPageLoading } from '@ui';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetStaticReportQuery } from '@app/store/api/get-static-report.api';
import { StaticReportType } from '@app/interfaces/staticReport';
import GridLayout from 'react-grid-layout';

import useResizeObserver from '@app/hooks/use-resize-observer';

import useTranslation from '@app/hooks/use-translation';

import ChartComponent from './chart-component';
// a5e0f9d6-d478-418f-adc7-2eb997a98c7f
const LAYOUT_ROW_HEIGHT = 70;

const EmailPage = () => {
  const { t } = useTranslation('common.constants');
  const [hideComponentsToggle, toggleHideComponents] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StaticReportType>();
  const [params] = useSearchParams();
  const [fetchReport] = useLazyGetStaticReportQuery();
  const layoutSettings = useMemo(
    () =>
      data?.graphs.map(({ layout }, index) => ({
        ...layout,
        i: `key_${index + 1}`,
      })),
    [data?.graphs],
  );
  const getReport = useCallback(async () => {
    const hash = params?.get?.('hash');
    if (!hash) return;
    fetchReport({ hash })
      .unwrap()
      .then((data) => setData(data))
      .finally(() => setTimeout(() => setLoading(false), 400));
  }, [fetchReport, params]);
  console.log(data);

  useEffect(() => {
    setLoading(true);
    getReport();
  }, [getReport]);
  const [gridLayoutLoading, changeGridLayoutLoading] = useState(true);
  const [gridWidth, changeGridWidth] = useState<number>(1055);
  const [layoutWidthRef, changeLayoutWidthRef] = useState<HTMLDivElement | null>(null);
  useResizeObserver(layoutWidthRef, changeGridWidth, changeGridLayoutLoading);

  function printReportHandler() {
    // const printBody = document.getElementById('printBody')?.innerHTML;
    // const windowPrint = window.open('', 'PRINT', 'height=400px, width=400px');
    // windowPrint?.document.write(`<html lang="RU"><head><title>${'test'}</title></head><body>`);
    // if (printBody) windowPrint?.document.write(printBody);
    // windowPrint?.document.write('</body></html>');
    // windowPrint?.close();
    // windowPrint?.print();
    toggleHideComponents(false);
    setTimeout(() => {
      window.print();
      toggleHideComponents(true);
    });
  }

  if (loading)
    return (
      <div className="w-full h-[calc(100vh-70px)] flex flex-col p-6 gap-[10px]">
        <div className=" w-full">
          <SkeletonPageLoading />
        </div>
        <div className="h-full flex flex-wrap flex-col gap-[10px]">
          {Array(4)
            .fill('')
            .map((_, index) => (
              <Skeleton width="50%" height="47%" key={index} />
            ))}
        </div>
      </div>
    );
  console.log(layoutSettings);

  return (
    <div>
      <PageHeader label={data?.live_report.name}>
        {hideComponentsToggle && <Button onClick={printReportHandler} label={t('print_report')} />}
      </PageHeader>
      <div ref={(ref) => changeLayoutWidthRef(ref)} id="printBody" className="w-full h-full">
        {gridLayoutLoading ? null : (
          <GridLayout
            className="layout relative w-full h-fit z-[1]"
            draggableCancel={'.noDrag'}
            layout={layoutSettings}
            cols={24}
            rowHeight={LAYOUT_ROW_HEIGHT}
            width={gridWidth || 1000}
            // onDragStop={onLayoutChange}
            // onResizeStop={onLayoutChange}
            isDraggable={false}
            isResizable={false}
            compactType={null}
            preventCollision={true}
            containerPadding={[10, 10]}
          >
            {data?.graphs.map(({ data }, index) => (
              <div key={`key_${index + 1}`} className={'w-fit h-fit'}>
                <ChartComponent data={data} />
              </div>
            ))}
          </GridLayout>
        )}
      </div>
    </div>
  );
};

export default EmailPage;
