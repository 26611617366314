module.exports = {
  title: 'Settings',
  warning: 'Warning',
  invalid_format: 'Invalid data format',
  navigation: {
    common_settings: 'General settings',
    alias: 'Aliases',
    recognition_rules: 'Recognition rules',
    integration_settings: 'Integration',
    manual_recording_upload: 'Manual uploading of records',
    // messagesHistory: 'Messages history',
    messagesHistory: 'Messages',
    messagesManaging: 'Message Recipients',
    // messagesManaging: 'Managing recipients of information messages',
  },
  manual_upload: {
    toast_error_upload: 'File sending error',
    toast_success: 'All files successfully uploaded',
    title: 'Specify metadata for files to be uploaded',
    add_metadata: 'Select how to fill in the metadata',
    fill_manual: 'Fill in manually',
    or: 'or',
    save_button: 'Upload records',
    reset_button: 'Reset',
    metadata: 'Metadata',
    alias: 'Alias',
    csv_template: 'CSV file template for upload',
    type: 'Data type',
    params: 'Value',
    close: 'Close'
  },
  recog_rules: {
    table: {
      name: 'Name',
      rules: 'Record selection condition',
      asr_model_id: 'Recognition Model',
      actions: 'Actions',
      status: 'Status'
    },
    modal_label: 'Create recognition rule',
    modal_label_edit: 'Edit recognition rule',
    modal_title_recognize: 'Recognize for the period',
    modal_label_recognize: 'Rule name',
    recognition_rules_queue: 'Recognition queue size ',
    from: 'from',
    to: 'to',
    add_to_recognition_queue: 'Add to recognition queue',
    recognition_rules: 'Recognition rules',
    interval_error: 'Incorrect interval',
    recognition_model_default: 'Default Recognition Model',
    recognition_rules_comment:
      'Note. All records that do not fall into other selection criteria',
    add_recognition_rule: 'Add rule',
    form: {
      name: 'Name',
      placeholder_name: 'Name',
      filter_rules: 'Filter rules',
      recog_model: 'Recognition Model',
      choose_placeholder: 'Choose...',
      button_label_delete: 'Delete rule',
      enabled: 'Activity',
      on: 'Rule is enabled',
      off: 'Rule is disabled'
    },
    billing: {
      billing_this_month: 'Records processed in the current calendar month: ',
      billing_prev_month: 'Records processed in the previous calendar month: ',
      records: ' records, ',
      minutes: ' min. '
    }
  },
  integrations:{
    integrations_title: 'Integrations',
    add_integration: 'Add integration',
    delete_integration_text: 'Do you really want to remove this integration?',
    table: {
      name: 'Integration name',
      description: 'Integration description',
      type: 'Integration type',
      actions: 'Actions',
      restapi: 'Loading REST records',
      integrationapi: 'Integration API'
    },
    modal_title: {
      create: 'Creating REST API integration',
      edit: 'Editing REST API integration',
    },
    form: {
      name: 'Name',
      type: 'Integration type',
      type2: 'Integration type: ',
      saveButton: 'Save',
      newKeysButton: 'Create new key pair',
      deleteButton: 'Remove integration',
      types: {
        rest: 'Loading REST records',
        integrationapi: 'Integrational API',
      },
    },
    createdIntModalLabel: 'Generating a keychain',
    createdIntModalKey: 'Copy this access key.',
    createdIntModalDescription: 'Once this window is closed, the key cannot be seen again.',
  },
  common_setting: {
    table: {
      name: 'Name',
      channel: 'Channel number',
      actions: 'Actions'
    },
    delete_channel_text: 'Do you really want to delete this channel?',
    form: {
      icon: 'Icon',
      operator: 'Operator',
      client: 'Client',
      other: 'Other',
      channel_name: 'Channel name',
      channel_name_placeholder: 'Name',
      button_label: 'Save',
      channel_number: 'Channel number',
    },
    title: 'Audio channels',
    modal_title: {
      edit: 'Edit channel',
      create: 'Add channel',
    },
    button: {
      add_channel: 'Add channel',
    },
  },
  alias: {
    add_alias_button: 'Add alias',
    table: {
      name: 'Name',
      meta_name: 'Metadata name',
      type_value: 'Value type',
      type_meta: 'Metadata type',
      utils: 'Actions',
    },
    popup_hints: {
      templates_list_count: 'Number of templates in group',
      delete: 'Delete',
      setting: 'Set up',
      recognize: 'Recognize for the period',
      edit_model: 'Select recognition model',
    },
    alias_title: 'Aliases',
    delete_alias_text: 'This action will affect system operation',
    delete_alias_text_alias_is_using:
      'This alias is used in other system settings and therefore cannot be deleted',
    modal_label: 'Create alias',
    modal_label_edit: 'Edit alias',
    values_translate: {
      str: 'Text',
      num: 'Number',
      system: 'System',
      base: 'Base',
      record: 'Record',
      channel: 'Channel',
    },
    form: {
      alias: 'Alias',
      meta: 'Metadata',
      type_meta: 'Metadata type',
      type_value: 'Value type',
      text: 'Text',
      number: 'Number',
      record_level: 'Records levels',
      channel_level: 'Channel levels',
      button_label: 'Save',
      button_label_delete: 'Delete alias',
      placeholder_name: 'Name',
      placeholder_meta_name: 'Value',
    },
  },
  messages_managing:{
    description: "Recepient's name",
    email: "E-mail",
    dt_add: "Date",
    confirmed: "Confirmation",
    action: "Actions",
    subscribe: "Subscription",
    deleteRecipientText: 'Do you really want to delete this recipient?',
    tg_template: 'Telegram messages recipients',
    email_template: 'E-mail recipients',
    true: 'Verification complete',
    false: 'Verification failed',
    add_recipient_button:"Add recipient"
  },
  messages_queue: {
    table: {
      dt_add: 'Message Date and time',
      recipient_name: 'Message recepient',
      status: 'Status from delivery system',
      priority: 'Priority'
    },
    NEW:'', 
    FAIL: '', 
    SUCCESS:'', 
    TTL:''

  }
};
