import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, Select } from '@ui';
import {
  AllChanelsArrType,
  MetricClassifierModelsType,
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { OptionItem } from '@app/components/ui/select/select.type';
import { ColorInput } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/color-input/color-input';
import { useParams } from 'react-router';
import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';
import useTranslation from '@app/hooks/use-translation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
type ClassifierFormType = {
  name: string;
  description: string;
  settings: {
    channel: string;
    model_id: string;
    class_id: string;
    activeColor: string;
    exclude_begin_sec: number;
    exclude_end_sec: number;
    probably: number;
  };
};

type ClassifierMetricFormPropsType = {
  onClose: () => void;
  metricType: MetricTypesArrType;
  classifierModels: MetricClassifierModelsType[] | undefined;
  allChannels?: AllChanelsArrType[];
  metricSettings: MetricObjType;
  editModal: boolean;
};
export const ClassifierMetricForm: React.FC<ClassifierMetricFormPropsType> = (props) => {
  const { metricType, classifierModels, allChannels, onClose, editModal, metricSettings } = props;
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');

  const { id } = useParams();
  // api
  const [createMetric] = useCreateProjectMetricGroupMutation();
  const [editMetric] = useEditProjectMetricGroupMutation();
  const hide = false;
  // api
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm<ClassifierFormType>({
    defaultValues: editModal
      ? { ...metricSettings }
      : {
          // settings: { channel: 'all', probably: 0, exclude_begin_sec: 0, exclude_end_sec: 0 },
          settings: {},
        },
  });
  console.log(metricSettings);

  function onSubmit(data) {
    const obj: MetricObjType = {
      name: data.name,
      description: data.description,
      type: metricType.type,
      result_value_type: metricType.result_value_type,
      // visualization: metricType
      settings: data.settings,
    };
    onClose();
    if (editModal) {
      id &&
        metricSettings.metric_id &&
        editMetric({
          params: { project_id: id, metric_id: metricSettings.metric_id },
          body: obj,
        });
    } else {
      id && createMetric({ params: { project_id: id }, body: obj });
    }
  }
  const classifierModelsOptions: OptionItem[] =
    classifierModels?.map((model) => ({
      value: model.model_id,
      title: model.name,
    })) || [];

  const classifierClasses = ((): OptionItem[] => {
    if (!watch('settings.model_id')) return [];
    const selectedModel = classifierModels?.find(
      (model) => model.model_id === watch('settings.model_id'),
    )?.classes;
    if (!selectedModel) return [];
    return selectedModel?.map((modelClass) => ({ value: modelClass.id, title: modelClass.name }));
  })();
  const clickColor = (event) => {
    setValue('settings.activeColor', event.currentTarget.value);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full relative">
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('metric_name')}</span>
            <div className="w-[280px]">
              <Input
                {...register('name', {
                  required: { value: true, message: tf('required') },
                })}
                placeholder={'place'}
                error={errors.name?.message}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('metric_description')}</span>
            <div className="w-[280px]">
              <Input
                {...register('description', {
                  required: { value: true, message: tf('required') },
                })}
                placeholder={'description'}
                error={errors.description?.message}
              />
            </div>
          </div>
          {hide && (
            <div className="flex items-center justify-between mr-[140px]  relative">
              <span className="max-w-[218px] text-0color text-[15px]">{t('channel_search')}</span>
              <Controller
                render={() => (
                  <div className="flex flex-col min-w-[130px] max-w-[130px] ">
                    <div className="flex items-center gap-[10px]">
                      <input
                        name="channel"
                        onChange={(e) => {
                          setValue('settings.channel', e.target.value);
                          clearErrors('settings.channel');
                        }}
                        type="checkbox"
                        value={'all'}
                        checked={watch('settings.channel') === 'all'}
                        className="focus:ring-offset-0 focus:ring-0 rounded-full"
                      />
                      <label className="text-[14px] text-0color">{t('all')}</label>
                    </div>
                    {allChannels?.map((chanel) => {
                      return (
                        <div
                          className="flex items-center gap-[10px]"
                          key={chanel.settings_channels_id}
                        >
                          <input
                            name="channel"
                            onChange={(e) => {
                              setValue('settings.channel', e.target.value);
                              clearErrors('settings.channel');
                            }}
                            type="checkbox"
                            checked={watch('settings.channel') === chanel.settings_channels_id}
                            value={chanel.settings_channels_id}
                            className="focus:ring-offset-0 focus:ring-0 rounded-full"
                          />
                          <label className="text-[14px] truncate text-0color">{chanel.name}</label>
                        </div>
                      );
                    })}
                  </div>
                )}
                name={'settings.channel'}
                control={control}
                rules={{
                  validate: (value) => !!value || tf('required'),
                }}
              />
              {errors?.settings?.channel?.message && (
                <div
                  className={
                    'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                  }
                >
                  {errors.settings.channel?.message}
                </div>
              )}
            </div>
          )}
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('classifier_model')}</span>
            <div className="w-[280px]">
              <Controller
                render={() => (
                  <Select
                    error={errors.settings?.model_id?.message}
                    defaultValue={watch('settings.model_id')}
                    options={classifierModelsOptions}
                    onChange={(value) => {
                      setValue('settings.model_id', value as string);
                      clearErrors('settings.model_id');
                    }}
                  />
                )}
                name={'settings.model_id'}
                rules={{
                  validate: (value) => !!value || tf('required'),
                }}
                control={control}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('classifier_theme')}</span>
            <div className="w-[280px]">
              <Controller
                render={() => (
                  <Select
                    disable={!watch('settings.model_id')}
                    error={errors.settings?.class_id?.message}
                    defaultValue={watch('settings.class_id')}
                    options={classifierClasses}
                    onChange={(value) => {
                      setValue('settings.class_id', value as string);
                      clearErrors('settings.class_id');
                    }}
                  />
                )}
                name={'settings.class_id'}
                rules={{ validate: (value) => value || tf('required') }}
                control={control}
              />
            </div>
          </div>
          {hide && (
            <div className="flex items-center justify-between relative">
              <span className="max-w-[218px] text-0color text-[15px]">{t('color')}</span>
              <Controller
                render={() => (
                  <div id="colors" className="flex items-center gap-[6px] mr-[23px]">
                    <ColorInput
                      onClick={(event) => {
                        clickColor(event);
                        clearErrors('settings.activeColor');
                      }}
                      active={watch('settings.activeColor') || ''}
                    />
                  </div>
                )}
                name={'settings.activeColor'}
                control={control}
                // rules={{ validate: (value) => !!value || tf('required') }}
              />
              {errors.settings?.activeColor?.message && (
                <div
                  className={
                    'absolute bottom-[-17px] right-[160px] text-sm text-red-600 dark:text-red-500'
                  }
                >
                  {errors.settings?.activeColor?.message}
                </div>
              )}
            </div>
          )}
          {hide && (
            <div className="flex items-center justify-between">
              <span className="text-0color max-w-[200px] text-[15px]">{t('probably')}</span>
              <div className="bg-transparent w-[180px] mr-[101px]">
                <Input
                  {...register('settings.probably', { valueAsNumber: true })}
                  max={1000000}
                  min={0}
                  type="number"
                  icon="ClockIcon"
                />
              </div>
            </div>
          )}
          {hide && (
            <div className="flex items-center justify-between">
              <span className="text-0color max-w-[200px] text-[15px]">
                {t('exclude_begin_sec')}
              </span>
              <div className="bg-transparent w-[180px] mr-[101px]">
                <Input
                  {...register('settings.exclude_begin_sec', { valueAsNumber: true })}
                  max={1000000}
                  min={0}
                  type="number"
                  icon="ClockIcon"
                />
              </div>
            </div>
          )}
          {hide && (
            <div className="flex items-center justify-between">
              <span className="text-0color max-w-[200px] text-[15px]">{t('exclude_end_sec')}</span>
              <div className="bg-transparent w-[180px] mr-[101px]">
                <Input
                  {...register('settings.exclude_end_sec', { valueAsNumber: true })}
                  max={1000000}
                  min={0}
                  type="number"
                  icon="ClockIcon"
                />
              </div>
            </div>
          )}
          {/*form end*/}
          <div></div>
          {/*footer*/}
          <div className="py-4">
            <div className="flex items-center gap-[15px]">
              <Button type="submit" label={t('save')} />
              <Button onClick={() => onClose()} fill="linked" label={t('cancel')} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
