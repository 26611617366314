import React, { useEffect, useState } from 'react';
import {
  useDeleteManyProjectsMutation,
  useEditManyProjectsMutation,
} from '@app/store/api/analytics-projects.api';

import {
  useGetChatGroupQuery,
  useCreateChatGroupMutation,
  useChangeStatusOfChatAnalyticProjectMutation,
  useDeleteChatProjectMutation,
} from '@app/store/api/chat-api/chat-analytic.api';
import { Button, Checkbox, EasyModal, EditText, Empty, PageHeader, Popover, Toggle } from '@ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { ColsWithoutTable } from '@app/components/rubber-table/blocks/cols-without-table';
import AccordionUI from '@app/components/ui/Accordion/Accordion';
import classNames from 'classnames';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import useTranslation from '@app/hooks/use-translation';
import { actionsMenu, tableColumns } from '@app/pages/analytics-projects/data';
import { useNavigate } from 'react-router-dom';
import {
  actionsIds,
  AllStatusProject,
  ProjectAnalyticsParams,
  ProjectItem,
} from '@app/interfaces/analytics';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { FolderIcon, SettingIcon, TrashIcon } from '@icons';
import AnalyticsStatus from '@app/components/ui/analytics-status/analytics-status';
import { toast } from 'react-toastify';

import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import RubberTable from '@app/components/rubber-table';

import { tableUtilIconsClassName } from '@app/pages/records/blocks/records-table/records-table.styles';

import { AccordionTitle } from './blocks/accordionTitle';

import AnalyticProjectSkeleton from './blocks/analytic-project.skeleton';

const ChatAnalyticPage = () => {
  const { t } = useTranslation('components.reportsStatusLabel');
  const { t: ta } = useTranslation('pages.chatAnalytics');
  const navigate = useNavigate();
  const { data: chatGroupData, isLoading } = useGetChatGroupQuery(undefined, {
    pollingInterval: 10000,
  });
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [createNewGroupRequest] = useCreateChatGroupMutation();
  const [deleteAnalyticProject] = useDeleteChatProjectMutation();
  const [deleteManyAnalyticProjects] = useDeleteManyProjectsMutation();
  const [editManyAnalyticProjects] = useEditManyProjectsMutation();
  const [changeStatus] = useChangeStatusOfChatAnalyticProjectMutation();
  //api
  //useState
  const [showChangeToOtherGroupModal, setShowChangeToOtherGroupModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showDeleteSelectedProjectsModal, setShowDeleteSelectedProjectsModal] = useState(false);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState<ProjectItem>();
  const [activeAccordionItem, setActiveAccordionItem] = useState<Array<string | undefined>>(
    userSettings?.accordions?.analyticsProjects || [],
  );
  const [createGroup, changeCreateGroup] = useState(false);
  const [groupName, setNewGroupName] = useState('');
  const [selectedProjects, changeSelectedProjects] = useState<ProjectAnalyticsParams[]>([]);
  //useState
  useEffect(() => {
    setActiveAccordionItem(userSettings?.accordions?.analyticsProjects || []);
  }, [userSettings?.accordions?.analyticsProjects]);
  //data
  const getTableData = (id: string) => {
    const group = chatGroupData?.find((group) => group.project_group_id === id);
    if (!group) return [];
    return group.projects.map((project) => ({
      name: (
        <div
          className="flex items-center pl-[4px]"
          data-id={`preset-template-${group.name}-${project.name}`}
        >
          <Checkbox
            disabled={!disableTrashIcon(project)}
            checked={selectedProjects?.includes(project)}
            onChange={() =>
              // disableTrashIcon(project) &&
              changeSelectedProjects((prev) =>
                prev.includes(project)
                  ? prev.filter((item) => item.project_id !== project.project_id)
                  : [...prev, project],
              )
            }
          />
          <div className="h-[28px] min-w-[28px] max-w-[28px] flex items-center justify-center ml-[16px] mr-[12px] bg-blue_pal rounded-[6px]">
            <FolderIcon size={13} className="text-action" />
          </div>
          <div
            onClick={() => {
              AllStatusProject.REMOVING !== project.status &&
                navigate(`/${navigationRoutes.chatAnalytic}/${project.project_id}`);
              updateUserSettings({
                ...userSettings,
                lastProjectRecords: project?.project_id,
              });
            }}
            className="hover:text-action cursor-pointer text-0color font-[600] truncate tracking-tight text-[14px] leading-[110%]"
            data-id={project.name}
          >
            {project.name}
          </div>
        </div>
      ),
      status: (
        <div className="flex items-center">
          <div className="flex items-center justify-between w-[120px]">
            <AnalyticsStatus status={project?.status || 'CREATED'} />
            {getChangeableStatus(project) && toggleShowCondition(project) && (
              <div className="mt-[1px]">
                <Toggle
                  checked={project.status === AllStatusProject.ACTIVE}
                  variant="success"
                  onChecked={() => changeStatusProject(project)}
                />
              </div>
            )}
          </div>
        </div>
      ),
      open: (
        <div className="flex">
          <Button
            disabled
            data-id={`preset-template-${group.name}-${project.name}-records`}
            label={ta('records')}
            icon="PlayCircleIcon"
            fill="linked"
            className="ml-[-16px] mr-[4px] !text-[14px] !font-[600] leading-[17px]"
            onClick={() => clickRecordLink(project.project_id)}
          />
          <Button
            disabled
            data-id={`preset-template-${group.name}-${project.name}-reports`}
            label={ta('reports')}
            icon="MetricIcon"
            fill="linked"
            className="!text-[14px] !font-[600] leading-[17px]"
            link={`/${navigationRoutes.reports}?project=${project.project_id}`}
          />
        </div>
      ),
      actions: (
        <div className="flex pl-4 border-l border-[#F1F2F3]">
          <span
            data-id={`preset-template-${group.name}-${project.name}-configure`}
            onClick={() => {
              AllStatusProject.REMOVING !== project.status &&
                navigate(`/${navigationRoutes.chatAnalytic}/${project.project_id}`);
              updateUserSettings({
                ...userSettings,
                lastProjectRecords: project?.project_id,
              });
            }}
            className={'mr-[17px]'}
          >
            <SettingIcon
              hintTitle={ta('popup_hints.settings_icon')}
              size={18}
              className={tableUtilIconsClassName}
            />
          </span>
          <span
            style={project.status === AllStatusProject.ACTIVE ? { pointerEvents: 'none' } : {}}
            data-id={`preset-template-${group.name}-${project.name}-delete`}
            onClick={() => {
              if (disableTrashIcon(project)) {
                setProjectToBeDeleted(project);
                setShowDeleteProjectModal(true);
              }
            }}
          >
            {/*project.status === AllStatusProject.ACTIVE */}
            <TrashIcon
              hintTitle={ta('popup_hints.delete_icon')}
              size={18}
              className={classNames(
                project.status === AllStatusProject.ACTIVE
                  ? 'text-4color cursor-not-allowed'
                  : disableTrashIcon(project)
                  ? 'text-basic_red cursor-pointer'
                  : 'text-4color cursor-not-allowed',
              )}
            />
          </span>
        </div>
      ),
    }));
  };

  //data
  //handlers
  function changeStatusProject(project: ProjectItem) {
    if (!getChangeableStatus(project)) return;
    const status =
      project.status === AllStatusProject.ACTIVE
        ? AllStatusProject.STOPPING
        : project.status === AllStatusProject.CREATED
        ? AllStatusProject.FILLING
        : AllStatusProject.STARTING;
    const params = {
      params: {
        id: project.project_id,
      },
      body: {
        status: status,
      },
    };
    changeStatus(params).then((data) => {
      const { error } = data as { error: { status: number } };
      if (error) {
        error.status === 403 && toast.error(ta('cant_change_status'));
      }
      toast.success(
        `${ta('status_will_be_changed')} "${t(project.status.toLowerCase())}"
              ${ta('to')}
              "${t(project.status === AllStatusProject.ACTIVE ? 'pause' : 'active')}"`,
      );
    });
  }
  const createNewGroup = (name: string) => {
    setNewGroupName(name);
    if (name !== '') {
      createNewGroupRequest({ name });
    } else if (groupName === '') {
      toast.error(ta('cant_change_status'));
    }
    changeCreateGroup(false);
  };
  function disableTrashIcon(project) {
    if (project.owner) {
      return true;
    } else if (!project.owner) {
      return project.shared === 'EDIT';
    } else if (project.status === AllStatusProject.REMOVING) {
      return false;
    } else if (project.status === AllStatusProject.PAUSE) {
      return true;
    } else if (project.status === AllStatusProject.CREATED) {
      return true;
    }
  }
  function clickRecordLink(id: string) {
    updateUserSettings({ ...userSettings, lastProjectRecords: id });
    navigate(`/${navigationRoutes.projectRecords}/${id}`);
  }
  function getChangeableStatus(project: ProjectItem | Pick<ProjectItem, 'project_id' | 'status'>) {
    return (
      AllStatusProject.ACTIVE === project.status ||
      AllStatusProject.PAUSE === project.status ||
      AllStatusProject.CREATED === project.status
    );
  }
  function toggleShowCondition(project) {
    if (project.owner) {
      return true;
    } else {
      return project.shared === 'EDIT';
    }
  }
  const clickActions = (key: string) => {
    if (key === actionsIds.ACTIONS_MOVE_TO_GROUP) {
      setShowChangeToOtherGroupModal((prev) => !prev);
    } else if (key === actionsIds.ACTIONS_DELETE_SELECTED) {
      setShowDeleteSelectedProjectsModal((prev) => !prev);
    }
  };
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        userSettings?.accordions?.analyticsProjects &&
          updateUserSettings({
            ...userSettings,
            accordions: {
              ...userSettings?.accordions,
              analyticsProjects: [...(userSettings?.accordions?.analyticsProjects || []), event.id],
            },
          });
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = userSettings?.accordions?.analyticsProjects;
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            analyticsProjects: filteredAccordionListFromStorage,
          },
        });

        return prev.filter((item) => item !== event.id);
      }
    });
  };
  function deleteManyProjects() {
    deleteManyAnalyticProjects(selectedProjects);
    changeSelectedProjects([]);
  }
  function moveProjectsToGroup(id: string) {
    editManyAnalyticProjects({
      projects: selectedProjects,
      groupId: id,
    }).finally(() => setShowChangeToOtherGroupModal(false));
    changeSelectedProjects([]);
  }
  //handlers
  useEffect(() => {
    const accordionListFromStorage = userSettings?.accordions?.analyticsProjects;
    if (!accordionListFromStorage?.length) {
      const defaultGroup = chatGroupData?.find(
        (group) => group.is_default === true,
      )?.project_group_id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup &&
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            analyticsProjects: [defaultGroup],
          },
        });
    }
  }, [chatGroupData, updateUserSettings, userSettings]);
  if (isLoading) return <AnalyticProjectSkeleton />;
  if (!Array.isArray(chatGroupData)) return <div>not array.</div>;
  return (
    <div>
      <PageHeader label={ta('analytic_proj')} icon="FolderIcon">
        <div className="flex items-center justify-items-end">
          <Button
            data-id="new-ap-template"
            label={ta('create_new_project_button')}
            icon="CirclePlusIcon"
            className="ml-[28px] mr-[16px]"
            onClick={() => navigate(navigationRoutes.newChatProject)}
          />
          <Popover
            label={ta('actions')}
            menu={actionsMenu.map((item) => ({
              ...item,
              disabled: !selectedProjects?.length,
              title: ta(item.title),
            }))}
            position="end"
            onClick={(key) => clickActions(key)}
          />
        </div>
      </PageHeader>
      <div className="flex flex-col gap-[20px]">
        <div className="relative">
          {!!chatGroupData.length && (
            <ColsWithoutTable
              columns={tableColumns.map((item) => ({ ...item, title: ta(item.title) }))}
            />
          )}
          <AccordionUI>
            {chatGroupData.length ? (
              chatGroupData.map((group) => (
                <div
                  key={group.project_group_id}
                  className={classNames(
                    activeAccordionItem?.includes(group.project_group_id) && '',
                    group.project_group_id === 'new' && '',
                  )}
                >
                  <AccordionItemUI
                    id={group.project_group_id}
                    dataId={`analytic-project-${group.name}`}
                    disabled={
                      activeAccordionItem.length === 1
                        ? activeAccordionItem[0] === group.project_group_id
                        : false
                    }
                    title={
                      <AccordionTitle
                        name={group.name}
                        isDefault={group.is_default}
                        active={activeAccordionItem?.includes(group.project_group_id)}
                        presetsCount={group.projects.length}
                        preset_group_id={group.project_group_id}
                        setActiveAccordionItem={setActiveAccordionItem}
                      />
                    }
                    clickBtnChild={clickAccordionHeaderBtn}
                    active={activeAccordionItem.includes(group.project_group_id)}
                    variant="filled"
                  >
                    <RubberTable
                      skeletons={10}
                      isEmpty={ta('not_projects')}
                      columns={tableColumns}
                      withoutCols
                      dataSource={getTableData(group.project_group_id)}
                    />
                  </AccordionItemUI>
                </div>
              ))
            ) : (
              <Empty title={ta('not_groups')} />
            )}
            {createGroup && (
              <AccordionItemUI
                variant="filled"
                title={
                  <div className="max-w-[240px]">
                    <EditText
                      textStyle="font-[700] text-[13px] text-2color leading-[14px]"
                      editIconSize={14}
                      text={groupName}
                      onEdited={createNewGroup}
                      dataId="new-analytic-project-group-name"
                      dataIdSubmit="new-analytic-project-group-name-submit"
                      defaultOpen
                    />
                  </div>
                }
                id={''}
              >
                {''}
              </AccordionItemUI>
            )}
          </AccordionUI>
        </div>
        <div>
          <Button
            data-id="new-group"
            icon="CirclePlusIcon"
            label={ta('new_group')}
            onClick={() => changeCreateGroup((prev) => !prev)}
          />
        </div>
      </div>
      {/*//modals*/}
      <EasyModal
        dataId={`${projectToBeDeleted?.name}-delete-confirm`}
        show={showDeleteProjectModal}
        onClose={setShowDeleteProjectModal.bind(null, false)}
        label={`${ta('delete_proj')} "${projectToBeDeleted?.name}"`}
        variant="removal"
        disabled={projectToBeDeleted?.status === 'ACTIVE'}
        onRemove={() =>
          projectToBeDeleted?.project_id &&
          deleteAnalyticProject({ id: projectToBeDeleted?.project_id })
        }
        withoutFooter
      >
        <p className="px-[15px] text-center">
          {projectToBeDeleted?.status === 'ACTIVE'
            ? ta('delete_proj_active')
            : ta('delete_proj_confirm')}
        </p>
      </EasyModal>
      <EasyModal
        show={showDeleteSelectedProjectsModal}
        onClose={setShowDeleteSelectedProjectsModal.bind(null, false)}
        label={`${ta('delete_selected_proj')} ${selectedProjects.length}`}
        variant="removal"
        onRemove={() => deleteManyProjects()}
        withoutFooter
      >
        <p className="px-[15px]">{ta('delete_projects_confirm')}</p>
      </EasyModal>
      <EasyModal
        show={showChangeToOtherGroupModal}
        onClose={setShowChangeToOtherGroupModal.bind(null, false)}
        label={ta('move_group')}
        variant={'mediumW650'}
        withoutFooter
      >
        <div className="rounded-lg border">
          {chatGroupData.map((group, index) => {
            return (
              <div
                key={`changeToOtherGroup-${group.project_group_id}-${index}`}
                className={`flex items-center px-[26px] pt-[20px] pb-[19px] text-lg font-medium cursor-pointer ${
                  index < chatGroupData.length - 1 ? 'border-b' : ''
                }`}
                onClick={() => {
                  moveProjectsToGroup(group.project_group_id);
                }}
              >
                <span className="grow">{group.name}</span>
                <i className="inline-block p-1 border-r-[2px] border-b-[2px] border-[#3D4858] rotate-[-45deg]"></i>
              </div>
            );
          })}
        </div>
      </EasyModal>
      {/*//modals*/}
    </div>
  );
};

export default ChatAnalyticPage;
