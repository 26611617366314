import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';
import { ChatRecordType, RecordBulkChats } from '@app/interfaces/record';

import { baseQuery } from '../../baseQuery';

export const allChatsApi = createApi({
  reducerPath: 'allChatsApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getChats: build.query<RecordBulkChats, GetFilterParamsNew>({
      query: (body) => ({
        url: serverRoutes.allChats,
        body,
        method: 'POST',
      }),
    }),
    getChatDetails: build.query<
      ChatRecordType,
      { params: { chat_id: string; project_id?: string } }
    >({
      query: ({ params }) => ({
        url: serverRoutes.singleChat,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetChatsQuery, useGetChatDetailsQuery } = allChatsApi;
