import React, { useEffect, useState } from 'react';

import { RecordActionsDropdown } from '@app/interfaces/records';

import useTranslation from '@app/hooks/use-translation';
import {
  Button,
  EasyFilterView,
  EasyModal,
  PageHeader,
  PermissionAccessWrapper,
  Popover,
} from '@ui';
import { PopoverMenuItem } from '@app/components/ui/popover/popover.type';
import { userPermissions } from '@app/utils/user-permissions';
import ManualRecordingUploadPage from '@app/pages/settings/manual-recording-upload.page';
import RecordsTableBlock from '@app/pages/records/blocks/records-table/records-table.block';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { GetFilterParamsNew, Filter, Range } from '@app/components/ui/easy-filter/types';
import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import {
  useCreateSinglePresetMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';
import { useNavigate } from 'react-router-dom';
import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';
import { useDeleteRecordsMutation } from '@app/store/api/records.api';
import { ChangeColumnDisplayModalBlock } from '@app/pages/records/blocks/change-column-display-modal.block';
import { useAppSelector } from '@app/store/store';

const DEFAULT_TABLE_LIMIT = 10;

const RecordsPage: React.FC = () => {
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const navigate = useNavigate();
  const DEFAULT_FILTER_SETUP: GetFilterParamsNew = {
    offset: 0,
    limit: userSettings?.tablesLimit?.allRecords || DEFAULT_TABLE_LIMIT,
    preset_mode: false,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  };
  const { t } = useTranslation('pages.allRecords');
  const { data, isLoading } = useGetAliasesListQuery();
  const [recordsParams, getRecordsParams] = useState<GetFilterParamsNew>(DEFAULT_FILTER_SETUP);
  const [selectedRecords, changeSelectedRecords] = useState<string[]>([]);
  const [columnDisplayFormModalValue, setColumnDisplayFormModalValue] = useState(false);
  const [deleteSelectedRecords, changeDeleteSelectedRecordsModalState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  //api
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [createPreset] = useCreateSinglePresetMutation();
  const [deleteRecordRequest] = useDeleteRecordsMutation();

  useEffect(() => {
    userSettings?.tablesLimit?.allRecords &&
      getRecordsParams((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.allRecords as number,
      }));
  }, [userSettings?.tablesLimit?.allRecords]);

  const actionsDropdown: PopoverMenuItem[] = [
    {
      title: t('popover.download_records'),
      key: RecordActionsDropdown.DOWNLOAD_RECORDS,
      icon: 'DownloadCloudIcon',
      disabled: !selectedRecords.length,
    },
    {
      title: t('popover.delete_selected'),
      key: RecordActionsDropdown.DELETE_SELECTED,
      icon: 'TrashIcon',
      color: 'danger',
      disabled: !selectedRecords.length,
      permission: { tag: userPermissions.action.actionDeleteRecords, permissions: 'action' },
    },
  ];

  const clickActionsDropdown = (key: string) => {
    if (key === RecordActionsDropdown.DOWNLOAD_RECORDS) {
      for (const record_id of selectedRecords) {
        downloadAudioHandler(record_id);
      }
    } else if (key === RecordActionsDropdown.DELETE_SELECTED) {
      changeDeleteSelectedRecordsModalState(true);
    }
  };
  function removeRecords() {
    deleteRecordRequest({ items: selectedRecords }).then(() => changeSelectedRecords([]));
  }

  const changeColumnDisplayFormModalState = () => {
    setColumnDisplayFormModalValue(true);
  };
  async function downloadAudioHandler(record_id: string) {
    return await getRecordAudio({ id: record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }
  function saveAsTemplatePreset() {
    if (!presetTemplates) return;
    const newPreset: PresetsCreateDataType = {
      name: t('system.preset_template_default_name'),
      description: '',
      preset_group_id:
        presetTemplates?.find((group) => group.is_default)?.preset_group_id ||
        presetTemplates?.[0]?.preset_group_id,
      shared: false,
      rules: recordsParams?.filter as Filter & Range,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }

  const getFooterFilter = (
    <div className="flex">
      <div className="grow">
        <Button
          data-id="save-as-new-preset"
          fill="linked"
          label={t('filter.buttons.save_as_template')}
          className="mr-[-22px]"
          onClick={() => saveAsTemplatePreset()}
          disabled={!recordsParams.filter.filter.length}
        />
        <Button
          fill="linked"
          label={t('filter.buttons.reset_filter')}
          disabled={
            !recordsParams.filter.filter.length && !recordsParams.filter.range.parameters.length
          }
          onClick={() => getRecordsParams(DEFAULT_FILTER_SETUP)}
        />
      </div>
    </div>
  );

  function getFilteringKeys() {
    return data?.aliases
      .filter((item) => !item.forbidden_rules)
      .map((alias) => ({
        id: alias.alias_id,
        title: alias.name,
        type_meta: alias.type_meta,
        type_value: alias.type_value,
      }));
  }
  function onChangeFilterHandler(data: Filter & Range) {
    getRecordsParams((prev) => ({ ...prev, filter: data }));
  }

  return (
    <div>
      <PageHeader label={t('pageTitle')} icon="PlayCircleIcon">
        <div className="flex items-center gap-[15px]">
          <PermissionAccessWrapper
            tag={userPermissions.action.actionUploadRecord}
            permissions={'action'}
          >
            {process.env.REACT_APP_THEME === 'rtk' ? (
              <div></div>
            ) : (
              <Button
                fill="outlined"
                hintTitle={t('popup_hints.upload_modal')}
                icon="PlusIcon"
                onClick={openModal}
              />
            )}
          </PermissionAccessWrapper>
          <Popover
            size={270}
            label={t('popover.buttonLabel')}
            menu={actionsDropdown}
            position="end"
            onClick={(key) => clickActionsDropdown(key)}
          />
          <Button
            hintTitlePosition="left"
            hintTitle={t('popup_hints.data_display_set_up')}
            icon="ColumnDisplayIcon"
            onClick={() => changeColumnDisplayFormModalState()}
          />
        </div>
      </PageHeader>
      <EasyFilterView
        dataIdStartDate="all-records-date-from"
        dataIdEndDate="all-records-date-to"
        isLoading={isLoading}
        footerMarkup={getFooterFilter}
        onChangeHandler={onChangeFilterHandler}
        keysForFiltering={getFilteringKeys() || []}
        fillFromTemplate
        data={recordsParams.filter}
      />
      <RecordsTableBlock
        changeSelectedRecords={changeSelectedRecords}
        selectedRecords={selectedRecords}
        recordsParams={recordsParams}
        getRecordsParams={getRecordsParams}
      />
      <ChangeColumnDisplayModalBlock
        value={columnDisplayFormModalValue}
        recordsParams={recordsParams}
        setValue={setColumnDisplayFormModalValue}
      />
      {/*  delete rec modal*/}
      <EasyModal
        show={deleteSelectedRecords}
        onClose={changeDeleteSelectedRecordsModalState.bind(null, false)}
        label={
          <div className="flex items-center justify-center gap-[5px]">
            <div>{t('multi_delete_records_modal_label')}</div>
            <span className="text-basic_red">
              {selectedRecords.length} {t('records_for_delete_modal')}
            </span>
          </div>
        }
        onRemove={removeRecords}
        variant="removal"
        withoutFooter={true}
      >
        <div className="flex items-center justify-center w-full">
          {t('multi_delete_records_modal_description')}
        </div>
      </EasyModal>
      <div className="w-full">
        {isModalOpen && (
          <EasyModal show={isModalOpen} onClose={closeModal} variant="largeW1200">
            <ManualRecordingUploadPage onCloseModal={closeModal} />
          </EasyModal>
        )}
      </div>
      {/*  delete rec modal*/}
    </div>
  );
};

export default RecordsPage;
