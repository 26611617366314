import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { NavigationRoute, navigationRoutes, NavigationRoutes } from '@app/utils/navigation-routes';
import { userPermissions } from '@app/utils/user-permissions';

export type MenuBarItem = {
  id: number;
  name: string;
  icon: IconNameType;
  iconSize?: number;
  iconSizeHidden?: number;
  routeEnd?: boolean;
  route: NavigationRoutes[NavigationRoute];
  tag?: string;
  soon?: boolean;
};
export type MenuBarList = {
  id: number;
  title?: string;
  menuItems: Array<MenuBarItem>;
};

export type MenuSecondBarList = {
  id: number;
  name: string;
  routeEnd?: boolean;
  route: NavigationRoutes[NavigationRoute];
};

const speechTemplateMenuBar: MenuBarList[] = [
  {
    id: 1,
    title: 'main',
    menuItems: [
      {
        id: 1,
        name: 'dashboard',
        icon: 'HomeIcon',
        route: navigationRoutes.main,
        routeEnd: true,
        tag: userPermissions.display.displayDashboards,
      },
      {
        id: 2,
        name: 'all_records',
        icon: 'PlayCircleIcon',
        route: navigationRoutes.records,
      },
    ],
  },
  {
    id: 2,
    title: 'analytic_title',
    menuItems: [
      {
        id: 3,
        name: 'analytic_proj',
        icon: 'FolderIcon',
        route: navigationRoutes.analytics,
        tag: userPermissions.display.displayProjects,
      },
      {
        id: 4,
        name: 'proj_records',
        icon: 'FolderMediaIcon',
        route: navigationRoutes.projectRecords,
      },
      {
        id: 5,
        name: 'reports',
        icon: 'MetricIcon',
        route: navigationRoutes.reports,
      },
      {
        id: 13,
        name: 'words_n_gramm',
        icon: 'CloudIcon',
        route: navigationRoutes.wordsNGramm,
      },
      // {
      //   id: 12,
      //   name: 'words_cloud',
      //   icon: 'CloudIcon',
      //   route: navigationRoutes.wordsCloud,
      // },
    ],
  },
  {
    id: 3,
    title: 'templates_title',
    menuItems: [
      {
        id: 6,
        name: 'presets_templates',
        icon: 'MenuSmallIcon',
        route: navigationRoutes.presets,
        tag: userPermissions.display.displayProjectPreset,
      },

      {
        id: 8,
        name: 'metrics_templates',
        icon: 'TemplateListIcon',
        route: navigationRoutes.metric,
        tag: userPermissions.display.displayMetricTemplates,
      },
    ],
  },
  {
    id: 4,
    title: 'settings_title',
    menuItems: [
      {
        id: 9,
        name: 'dictionaries',
        icon: 'BookIcon',
        route: navigationRoutes.dictionaries,
        tag: userPermissions.display.displayDictionaries,
      },
      {
        id: 10,
        name: 'system_settings',
        icon: 'SettingIcon',
        route:
          process.env.REACT_APP_THEME === 'rtk'
            ? `${navigationRoutes.settings}/${navigationRoutes.messagesManaging}`
            : navigationRoutes.settings,
        tag: userPermissions.display.displaySettings,
      },
      {
        id: 11,
        name: 'audit_logs',
        icon: 'BookOpenIcon',
        route: navigationRoutes.auditLogs,
        tag: userPermissions.display.displayAuditLogs,
      },
      {
        id: 14,
        name: 'users',
        icon: 'UserIcon',
        route: navigationRoutes.users,
        tag: userPermissions.display.displayUsers,
      },
    ],
  },
];

// chat menu bar

const chatTemplateMenuBar: MenuBarList[] = [
  {
    id: 1,
    title: 'main',
    menuItems: [
      {
        id: 1,
        name: 'dashboard',
        icon: 'HomeIcon',
        route: navigationRoutes.main,
        routeEnd: true,
        tag: userPermissions.display.displayDashboards,
      },
      {
        id: 2,
        name: 'all_chats',
        icon: 'PlayCircleIcon',
        route: navigationRoutes.allChats,
      },
    ],
  },
  {
    id: 2,
    title: 'chat_analytic',
    menuItems: [
      {
        id: 3,
        name: 'analytic_proj',
        icon: 'FolderIcon',
        route: navigationRoutes.chatAnalytic,
        tag: userPermissions.display.displayChatsProjects,
      },
      {
        id: 4,
        name: 'proj_records',
        icon: 'FolderMediaIcon',
        route: navigationRoutes.chatProjectRecords,
      },
      {
        id: 5,
        name: 'reports',
        icon: 'MetricIcon',
        route: navigationRoutes.chatReports,
      },
      {
        id: 13,
        name: 'words_n_gramm',
        icon: 'CloudIcon',
        route: navigationRoutes.wordsNGramm,
      },
      // {
      //   id: 12,
      //   name: 'words_cloud',
      //   icon: 'CloudIcon',
      //   route: navigationRoutes.wordsCloud,
      // },
    ],
  },
  {
    id: 3,
    title: 'templates_title',
    menuItems: [
      {
        id: 6,
        name: 'presets_templates',
        icon: 'MenuSmallIcon',
        route: navigationRoutes.presets,
        tag: userPermissions.display.displayProjectPreset,
      },
      {
        id: 8,
        name: 'chat_metric_templates',
        icon: 'TemplateListIcon',
        route: navigationRoutes.chatMetricTemplates,
        tag: userPermissions.display.displayChatProjectMetrics,
      },
    ],
  },
  {
    id: 4,
    title: 'settings_title',
    menuItems: [
      {
        id: 9,
        name: 'dictionaries',
        icon: 'BookIcon',
        route: navigationRoutes.dictionaries,
        tag: userPermissions.display.displayDictionaries,
      },
      {
        id: 10,
        name: 'system_settings',
        icon: 'SettingIcon',
        route:
          process.env.REACT_APP_THEME === 'rtk'
            ? `${navigationRoutes.settings}/${navigationRoutes.messagesManaging}`
            : navigationRoutes.settings,
        tag: userPermissions.display.displaySettings,
      },
      {
        id: 11,
        name: 'audit_logs',
        icon: 'BookOpenIcon',
        route: navigationRoutes.auditLogs,
        tag: userPermissions.display.displayAuditLogs,
      },
      {
        id: 14,
        name: 'users',
        icon: 'UserIcon',
        route: navigationRoutes.users,
        tag: userPermissions.display.displayUsers,
      },
    ],
  },
];

const menuBarDataByType: Record<'chat' | 'voice', MenuBarList[]> = {
  chat: chatTemplateMenuBar,
  voice: speechTemplateMenuBar,
};

export const menuBarData = (template: 'chat' | 'voice') => menuBarDataByType[template];

export const secondMenuList: Array<MenuSecondBarList> = [
  {
    id: 1,
    name: 'Speech Analytics',
    routeEnd: true,
    route: navigationRoutes.main,
  },
  {
    id: 2,
    name: 'Text-to-Speech',
    routeEnd: false,
    route: navigationRoutes.reports,
  },
  {
    id: 3,
    name: 'Assist Superviser',
    routeEnd: false,
    route: navigationRoutes.records,
  },
  {
    id: 4,
    name: 'Assist Conf',
    routeEnd: false,
    route: navigationRoutes.presets,
  },
];
