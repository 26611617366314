import React, { useState } from 'react';

import useTranslation from '@app/hooks/use-translation';
import {
  useCreateRecipientFromEmailMutation,
  useCreateRecipientFromTgMutation,
  useDeleteRecipientFromEmailMutation,
  useDeleteRecipientFromTgMutation,
  useEditRecipientFromEmailMutation,
  useEditRecipientFromTgMutation,
  useGetTenantRecipientsTgAndEmailQuery,
} from '@app/store/api/tenant-recipients.api';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import RubberTable from '@app/components/rubber-table';
import { SettingIcon, TrashIcon } from '@icons';
import { Button, EasyModal, Paginator, Select } from '@app/components/ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { TriggerPlatformType } from '@app/interfaces/metric-trigger.type';
import { MixedRecipientsRequestType } from '@app/interfaces/tenant-recipients.type';
import { TableFilter } from '@app/components/table/table.type';
import { AddRecipientsModal } from '@app/components';
import { Trans } from 'react-i18next';
const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;
const MessagesManagingPage = () => {
  const { t } = useTranslation('pages.settings.messages_managing');
  const { t: recipientsTranslation } = useTranslation('components.recipientsModal');
  const [platform, setPlatform] = useState<TriggerPlatformType>('telegram');
  const [recipientForDelete, setRecipientForDelete] = useState<string>();
  const [deleteRecipientFromEmail] = useDeleteRecipientFromEmailMutation();
  const [deleteRecipientFromTg] = useDeleteRecipientFromTgMutation();
  const [createEmailRecipient] = useCreateRecipientFromEmailMutation();
  const [createTgRecipient] = useCreateRecipientFromTgMutation();
  const [editEmailRecipient] = useEditRecipientFromEmailMutation();
  const [editTgRecipient] = useEditRecipientFromTgMutation();
  const [showCreateRecipientModal, changeModalState] = useState(false);
  const [editRecipientMode, changeEditMode] = useState(false);
  const [editableRecipientData, changeEditableRecipientData] = useState<{
    description: string;
    recipient_id: string;
  }>();
  const [filterParams, changeFilterParams] = useState<MixedRecipientsRequestType>({
    telegram: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
    email: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
  });
  const editRecipient = {
    telegram: editTgRecipient,
    email: editEmailRecipient,
  };
  const { data } = useGetTenantRecipientsTgAndEmailQuery(filterParams);
  const columns: TableColumn[] =
    platform === 'telegram'
      ? [
          { title: t('description'), filter: true, index: 'description' },
          { title: t('dt_add'), filter: true, index: 'dt_add' },
          { title: t('confirmed'), filter: true, index: 'confirmed' },
          { title: t('action'), index: 'action', divider: 'left', maxWidth: 100 },
        ]
      : [
          { title: t('description'), filter: true, index: 'description' },
          { title: t('email'), filter: true, index: 'email' },
          { title: t('dt_add'), filter: true, index: 'dt_add' },
          { title: t('confirmed'), filter: true, index: 'confirmed' },
          { title: t('action'), index: 'action', divider: 'left', maxWidth: 100 },
        ];
  const tableData: TableItem[] =
    data?.[platform].recipients.map((recipient) => ({
      ...recipient,
      confirmed: (
        <div className={`${recipient.confirmed ? 'text-basic_green' : 'text-basic_red'}`}>
          {t(String(recipient.confirmed))}
        </div>
      ),
      action: (
        <div className="flex items-center h-full w-full justify-center gap-[10px] pr-[20px]">
          <div
            onClick={() =>
              setEditRecipientMode({
                description: recipient.description,
                recipient_id: recipient.recipient_id,
              })
            }
            className="cursor-pointer "
          >
            <SettingIcon className="text-3color" size={18} />
          </div>
          <div
            onClick={() => setRecipientForDelete(recipient.recipient_id)}
            className="cursor-pointer "
          >
            <TrashIcon className="text-basic_red" size={18} />
          </div>
        </div>
      ),
    })) || [];

  function setEditRecipientMode(recipientInfo: { description: string; recipient_id: string }) {
    changeEditMode(true);
    changeEditableRecipientData(recipientInfo);
    changeModalState(true);
  }
  function onEditRecipientDescription(body: { description: string; recipient_id: string }) {
    editRecipient[platform](body).finally(() => {
      changeEditMode(false);
      changeEditableRecipientData(undefined);
      changeModalState(false);
    });
  }
  function handleRemoveRecipient() {
    if (!recipientForDelete) return;
    if (platform === 'telegram') {
      deleteRecipientFromTg({ recipient_id: recipientForDelete });
    } else {
      deleteRecipientFromEmail({ recipient_id: recipientForDelete });
    }
  }

  function handlePageChange({ offset, limit }: PaginationResponse) {
    platform === 'email'
      ? changeFilterParams((prev) => ({ ...prev, email: { ...prev.email, limit, offset } }))
      : changeFilterParams((prev) => ({ ...prev, telegram: { ...prev.telegram, limit, offset } }));
  }
  function handleFilter(filter: TableFilter) {
    const val = Object.entries(filter);
    const sortDesc = +val?.[0]?.[1] === 1;
    const sortBy = (val?.[0]?.[0] || undefined) as unknown as
      | 'description'
      | 'dt_add'
      | 'confirmed'
      | undefined;
    platform === 'email'
      ? changeFilterParams((prev) => ({
          ...prev,
          email: { ...prev.email, sortBy: sortBy, sortDesc: sortDesc },
        }))
      : changeFilterParams((prev) => ({
          ...prev,
          telegram: { ...prev.telegram, sortBy: sortBy, sortDesc: sortDesc },
        }));
  }

  function onCreateNewRecipient(
    platform: 'telegram' | 'email',
    body: { email: string; description: string },
  ) {
    return new Promise<{ status: boolean; recipient_id?: string; hash?: string }>((resolve) => {
      if (platform === 'email') {
        createEmailRecipient(body)
          .unwrap()
          .then((data) => {
            resolve({ status: true, recipient_id: data.recipient_id });
          })
          .catch(() => resolve({ status: false }))
          .finally(() => changeModalState(false));
        // .finally(() => setTimeout(() => refetch(), 100));
      } else {
        createTgRecipient(body)
          .unwrap()
          .then((data) => {
            // handleGetSubscribedRecipients(addRecipientTriggerId);
            resolve({ status: true, recipient_id: data.recipient_id, hash: data.hash });
          })
          .catch(() => resolve({ status: false }));
        // .finally(() => setTimeout(() => refetch(), 100));
      }
    });
  }
  return (
    <div>
      <div className="py-[30px] flex items-center justify-between gap-[100px]">
        <Select
          options={[
            { value: 'telegram', title: t('tg_template') },
            { value: 'email', title: t('email_template') },
          ]}
          defaultValue={platform}
          onChange={(value) => setPlatform(value as 'telegram' | 'email')}
        />
        <Button
          onClick={() => changeModalState(true)}
          label={t('add_recipient_button')}
          icon="CirclePlusIcon"
        />
      </div>
      <div className="flex flex-col gap-[20px]">
        <RubberTable onFilter={handleFilter} dataSource={tableData} columns={columns} />
        {data?.[platform].recipients?.length ? (
          <Paginator
            page={
              Math.ceil(
                Number(filterParams[platform].offset) / Number(filterParams[platform].limit),
              ) + 1
            }
            visiblePages={1}
            count={data?.[platform].total || 0}
            limit={Number(filterParams[platform].limit)}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
      {platform === 'telegram' && (
        <div className=" py-4 text-3color text-[14px]">
          <Trans
            i18nKey={recipientsTranslation('telegram_create_description', {
              tgLink: recipientsTranslation('tg_bot_link'),
            })}
            components={{
              botLink: (
                <a
                  className="underline whitespace-nowrap text-action"
                  href={recipientsTranslation('tg_bot_link')}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              ),
              manualLink: <a className="underline whitespace-nowrap text-action"></a>,
            }}
          />
        </div>
      )}
      <EasyModal
        variant="removal"
        show={!!recipientForDelete}
        onClose={() => setRecipientForDelete(undefined)}
        onRemove={handleRemoveRecipient}
      >
        {t('deleteRecipientText')}
      </EasyModal>
      <AddRecipientsModal
        editMode={editRecipientMode}
        editableRecipientData={editableRecipientData}
        show={showCreateRecipientModal}
        onClose={() => {
          if (editRecipientMode) {
            changeEditMode(false);
          }
          changeModalState(false);
        }}
        onlyForCreate
        activePlatformFromParent={platform}
        modalSizeVariant="mediumW650"
        onCreateNewRecipient={onCreateNewRecipient}
        onEditRecipientDescription={onEditRecipientDescription}
      />
    </div>
  );
};

export default MessagesManagingPage;
