module.exports = {
  chapter_label: 'Triggers',
  modal:{
    label:'Trigger settings',
    submit_button_label:  'Save',
    cancel_button_label: 'Cancel'
  },
  buttons: {
    add_trigger: 'Add trigger'
  },
  system: {
    warning: 'Warning',
    delete_trigger_text: 'Do you really want to remove the trigger?'
  },
  form: {
    filter_label: 'Trigger activation conditions',
    subject: 'Trigger name',
    description: 'Description',
    enabled: 'Status',
    preset: 'Selection conditions'
  },
  table: {
    subject: 'Name',
    recipients: 'Recipients',
    preset: 'Activation conditions',
    actions: 'Actions'
  },
  recipients_form: {
    telegram: 'Телеграм',
    email: 'Email',
    description_field: 'Recepient name',
    email_field: 'Email',
    add_recipient_button: 'Add recipient',
    descriptionError: 'Recipient name is required',
    emailError: 'Invalid e-mail',
    save: 'Save',
    cancel: 'Cancel'
  }
}
