import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { CommonSettingsPage, SettingsOutlet } from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';
import { BreadcrumbsItem } from '@app/layouts/layout';
import {
  AliasSettings,
  IntegrationSettingsPage,
  RecognitionRulesSettings,
  InformMessagesPage,
  MessagesManagingPage,
} from '@app/pages/settings';

import { userPermissions } from '@app/utils/user-permissions';

import RequiredPermission from './required-permission.route';

export const settingsRoute = (
  <Route
    path={navigationRoutes.settings}
    element={
      <RequiredAuth>
        <RequiredPermission tag={userPermissions.display.displaySettings}>
          <SettingsOutlet />
        </RequiredPermission>
      </RequiredAuth>
    }
  >
    <Route
      index
      element={
        <RequiredAuth>
          <CommonSettingsPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsAlias}
      element={
        <RequiredAuth>
          <AliasSettings />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsRecognitionRules}
      element={
        <RequiredAuth>
          <RecognitionRulesSettings />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.integration}
      element={
        <RequiredAuth>
          <IntegrationSettingsPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.messagesHistory}
      element={
        <RequiredAuth>
          <InformMessagesPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.messagesManaging}
      element={
        <RequiredAuth>
          <MessagesManagingPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
  </Route>
);
