import React, { FC, useState } from 'react';

import BoxSpoiler from '@app/components/ui/box-spoiler';
import { useTranslation } from '@app/hooks/use-translation';
import { CalculatedRecordMetric, KnownMetricsCases } from '@app/interfaces/records';
import { Toggle } from '@ui';
import { useAudioContext } from '@app/components/audio-build/record-provider';
import { SettingsMetricType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { tableTitleClassName } from './record-metric.style';
import MetricItem from './metric-item';

type RecordMetricProps = {
  projectMetric: CalculatedRecordMetric;
  metricCases?: KnownMetricsCases;
  onChangeActiveMetric: (metricId: string | undefined, type: 'once' | 'all') => void;
};

const RecordMetric: FC<RecordMetricProps> = (props) => {
  const { projectMetric, onChangeActiveMetric, metricCases } = props;
  const { recordId, projectId } = useAudioContext();
  const [activeMetricId, setActiveMetric] = useState<string>();
  const { t } = useTranslation('components.audio');
  const [isShowEmpty, changeShowEmpty] = useState<boolean>(false);
  function handleMetricItemAction({
    caseName,
    value,
  }: {
    caseName: string;
    value: string | number;
  }) {
    if (caseName === 'find-words') {
      const currentMetric = activeMetricId === String(value) ? undefined : String(value);
      setActiveMetric(currentMetric);
      onChangeActiveMetric?.(currentMetric, 'all');
    }
    if (caseName === 'find-words_once') {
      setActiveMetric(value as string);
      onChangeActiveMetric?.(value as string, 'once');
    }
  }

  const renderMetric = Object.entries(projectMetric)
    .filter(([, knownCase]) => (isShowEmpty ? true : !!knownCase.value))
    .map(([metricId, knownCase], index) => (
      <MetricItem
        isActive={metricId === activeMetricId}
        key={index}
        settings={metricCases?.[metricId]?.known_metric.settings as SettingsMetricType}
        data={knownCase as KnownMetricsCases[string]['known_metric']}
        recordID={recordId}
        projectID={String(projectId)}
        valueMaxWidth={180}
        onChangeMetricItem={handleMetricItemAction}
      />
    ));
  console.log(Object.entries(projectMetric));

  // ) : (
  //   <div key={index}>{typeof knownCase.value}</div>
  // ),

  const renderShowHideMetric = (
    <div className="flex items-center">
      <Toggle
        size="small"
        onChecked={changeShowEmpty.bind(null, (prevState) => !prevState)}
        checked={isShowEmpty}
      />
      <div className="ml-[8px] text-[12px] text-2color mb-[-4px]">{t('metric_show_empty')}</div>
    </div>
  );

  return (
    <BoxSpoiler title={t('metric_list')} sideComponent={renderShowHideMetric}>
      <div className="grid grid-cols-[1fr_250fr_50px] gap-2 px-[26px]">
        <div className={tableTitleClassName}>{t('metric')}</div>
        <div className={`${tableTitleClassName} text-right`}>{t('metric_value')}</div>
        <div></div>
      </div>
      <div className="py-[15px] pb-[20px]">{renderMetric}</div>
    </BoxSpoiler>
  );
};

export default RecordMetric;
